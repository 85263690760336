import React, { useState, useEffect } from 'react'
import './styles.scss'

// LOGO
import BinabudayaLogo from '../../images/homepage/binabud.svg'
import YES from '../../images/homepage/yes.png'
import AFS from '../../images/homepage/afs.png'
import Kakehashi from '../../images/homepage/kakehasi.png'
import BinabudCircle from '../../images/homepage/binabud-circle.png'

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';

// COMPONENT
import ModalToken from '../../components/modal/token'
import ModalNonToken from '../../components/modal/noToken'
import ChapterModal from '../../components/modal/chapterDetail'

import { useSelector } from 'react-redux'

const SelectButton = withStyles((theme) => ({
    root: {
        marginTop: 'auto',
        width: '156px',
        height: '50px',
        color: '#999999',
        backgroundColor: '#FFFFFF',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.04)',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
    },
}))(Button);

const ChapterButton = withStyles((theme) => ({
    root: {
        // marginTop: 'auto',
        width: '193px',
        height: '40px',
        color: '#3598DC',
        backgroundColor: '#FFFFFF',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15px',
        textAlign: 'center',
        border: '1px solid #3598DC',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#3598DC',
            border: '1px solid #3598DC',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
            borderRadius: '4px',
            color: '#FFFFFF'
        },
    },
}))(Button);

function Homepage(props) {
    const chapterList = useSelector(state=> state.chapterState.chapter )
    const [program, setProgram] = useState(null)
    const [chapterSearch, setChapterSearch] = useState('')
    const [chapterSelect, setChapterSelect] = useState(null)
    const [modalChapterToggle, setModalChapterToggle] = useState(false);
    const [modalNoTokenToggle, setModalNoTokenToggle] = useState(false);
    const [modalTokenToggle, setModalTokenToggle] = useState(false);

    useEffect(()=>{
        document.getElementById('html-overflow').style.overflow="overlay"
        if (props) {
            if (props.location) {
                if (props.location.state) {
                    if (props.location.state.chapter) {
                        document.getElementById('chapterTriggered').focus()
                    }
                }
            }
        }
    },[props])

    const modalHandler = (e, key, data, action) => {
        if (e) { e.preventDefault() }
        switch (key) {
            case 'chapter':
                if (!action) {
                    document.getElementById('html-overflow').style.overflow="overlay"
                    document.getElementById('modal-id').style.animation="popdown 0.3s ease-out"
                    setChapterSelect(null)
                    setTimeout(() => {
                        setModalChapterToggle(action)
                    }, 300);
                } else {
                    document.getElementById('html-overflow').style.overflow="hidden"
                    document.getElementById('modal-id').style.animation="popup 0.3s ease-out"
                    setModalChapterToggle(action)
                    setChapterSelect(data)
                }
                break;
            case 'noToken':
                if (!action) {
                    document.getElementById('html-overflow').style.overflow="overlay"
                    document.getElementById('modal-id-notoken').style.animation="popdown 0.3s ease-out"
                    setProgram(null)
                    setTimeout(() => {
                        setModalNoTokenToggle(action)
                    }, 300);
                } else {
                    document.getElementById('html-overflow').style.overflow="hidden"
                    document.getElementById('modal-id-notoken').style.animation="popup 0.3s ease-out"
                    setModalNoTokenToggle(action)
                    setProgram(data)
                }
                break;
            case 'token':
                if (!action) {
                    document.getElementById('html-overflow').style.overflow="overlay"
                    document.getElementById('modal-id-token').style.animation="popdown 0.3s ease-out"
                    setProgram(null)
                    setTimeout(() => {
                        setModalTokenToggle(action)
                    }, 300);
                } else {
                    document.getElementById('html-overflow').style.overflow="hidden"
                    document.getElementById('modal-id-token').style.animation="popup 0.3s ease-out"
                    setModalTokenToggle(action)
                    setProgram(data)
                }
                break;
            default:
                break;
        }
    }

    return (
        <div className="homepage-container-wrapper">
            <AppBar
                position="fixed"
                style={{
                    zIndex: 998,
                    padding: '10px 24px',
                    background: 'linear-gradient(90deg, #2785d8 0%, #66a6ff 100%)',
                    minHeight: '0px'
                }}
            >
                <div
                    className="alert-info-2131249"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    Gunakan hanya browser Google Chrome atau Firefox untuk mengakses Online Registration ini
                </div>
            </AppBar>
            <div className="homepage-wrapper">
                <div className="Logo-navbar-homepage">
                    <img src={BinabudayaLogo} alt="binabudaya" draggable={false} />
                </div>
                <div className="homepage-mid-contain" >
                    <span className="homepage-font-blue-big" >
                        Pendaftaran Seleksi Bina Antarbudaya<br/>2025-2026
                    </span>
                    <span className="homepage-font-blue-big-phone" >
                        Pendaftaran Seleksi<br/>Bina Antarbudaya<br/>2025-2026
                    </span>
                    <span className="homepage-font-grey" >
                        EXPLORE THE WORLD, EXPLORE YOURSELF
                    </span>
                    <span className="homepage-font-grey-phone" >
                        EXPLORE THE WORLD,<br/>EXPLORE YOURSELF
                    </span>
                    <span className="homepage-font-black-bold" >
                        Pilih Program di bawah ini yang akan adik ikuti:
                    </span>
                </div>
                <div className="homepage-program-list" >
                    <div className="homepage-program" style={{ marginLeft: 0, }}>
                        <img src={YES} alt="yes" draggable={false} style={{ width: '130px' }} />
                        <SelectButton variant="contained"
                            onClick={(e)=>[modalHandler(e, 'noToken', 'YES', true)]}
                        >
                            Pilih Program
                        </SelectButton>
                    </div>
                    <div className="homepage-program" >
                        <img src={AFS}  alt="yes" draggable={false} style={{ width: '150px', marginTop: '15px', }}  />
                        <SelectButton variant="contained"
                            onClick={(e)=>[modalHandler(e, 'token', 'AFS', true)]}
                        >
                            Pilih Program
                        </SelectButton>
                    </div>
                    {/* 2022 without KAKEHASHI PROGRAM */}
                    {/* <div className="homepage-program">
                        <img src={Kakehashi}  alt="yes" draggable={false} style={{ width: '148px' }} />
                        <SelectButton variant="contained"
                            onClick={(e)=>[modalHandler(e, 'noToken', 'KAKEHASHI', true)]}
                        >
                            Pilih Program
                        </SelectButton>
                    </div> */}
                </div>
            </div>

            <div className="homepage-wrapper-middle-one" >
                <div className="homepage-wrapper-middle" >
                    <span className="homepage-font-middle-contain" >
                        Tentang Bina Antarbudaya
                    </span>
                    <span className="homepage-font-middle-paragraf" >
                        Bina Antarbudaya adalah organisasi pendidikan antarbudaya berbasis relawan, yang memberikan kesempatan untuk memperoleh pendidikan antarbudaya secara global. Sejak didirikan pada tahun 1985, Bina Antarbudaya telah mengirim lebih dari 4500 siswa Indonesia dan menerima lebih dari 1500 siswa asing dari berbagai Negara. Saat ini Bina Antarbudaya telah memiliki 20 chapter di seluruh Indonesia. Bina Antarbudaya merupakan mitra AFS Intercultural Programs, salah satu organisasi pertukaran antarbudaya terbesar di dunia yang beroperasi di lebih dari 60 negara di lima benua.
                    </span>
                </div>
                <div className="homepage-middle-double" >
                    <span className="homepage-font-middle-double">
                        CHAPTER-CHAPTER BINA ANTARBUDAYA
                    </span>
                    <input
                        placeholder="Cari Chapter"
                        className="search-chapter-input"
                        onChange={e=> setChapterSearch(e.target.value || '')}
                    />
                    <a href="top" id="chapterTriggered" />
                    <div className="homepage-list-card" >
                        {
                            chapterList?.filter(chap => chap?.status === true)
                            .filter(chap => chapterSearch ? ((chap.chapter).toLowerCase()).includes((chapterSearch).toLowerCase()) : chap)
                            .sort((a,b) =>  a.chapter < b.chapter ? -1 : a.chapter > b.chapter ? 1 : 0)
                            .map((chapter)=>(
                                <div className="homepage-card" key={chapter?._id} onClick={(e)=>[modalHandler(e, 'chapter', chapter, true)]}>
                                    <img src={BinabudCircle} alt="Binabudaya"/>
                                    <div className="homepage-card-title" >
                                        <span className="card-font-top" style={{ textTransform: 'capitalize' }}>
                                            { chapter?.chapter }
                                        </span>
                                        <span className="card-font-middle" >
                                            {chapter?.alamat }
                                        </span>
                                    </div>
                                    <div className="card-font-bottom" >
                                        <span className="card-font-media" >
                                            { chapter?.mediaSosial?.[0] }
                                        </span>
                                    </div>
                                    {/* <ChapterButton variant="contained"
                                        onClick={(e)=>[modalHandler(e, 'chapter', chapter, true)]}
                                    >
                                        Kontak Chapter
                                    </ChapterButton> */}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className="homepage-footer" style={{ flexDirection: 'column' }}>
                <span className="footer-font text-footer-support" > Email Support : ina.support-yes@afs.org (YES) / ina.support-afs@afs.org (AFS) </span>
                <div className="text-footer-support-standin" >
                    <span className="footer-font" style={{ marginTop: 5 }} > Email Support : </span>
                    <span className="footer-font" style={{ marginTop: 5 }} > ina.support-yes@afs.org (YES) </span>
                    <span className="footer-font" style={{ marginTop: 5 }} > ina.support-afs@afs.org (AFS) </span>
                    {/* <span className="footer-font" style={{ marginTop: 5 }} > ina.support-kks@afs.org (ASIA KAKEHASHI PROJECT) </span> */}
                </div>
                <span className="footer-font" style={{ marginTop: 5 }} > 2023 © Yayasan Bina Antarbudaya </span>
            </div>

            <ChapterModal
                show={modalChapterToggle}
                modalClosed={modalHandler}
                children={"modal"}
                chapter={chapterSelect}
            />

            <ModalNonToken
                show={modalNoTokenToggle}
                modalClosed={modalHandler}
                children={"modal"}
                program={program}
            />

            <ModalToken
                show={modalTokenToggle}
                modalClosed={modalHandler}
                children={"modal"}
                program={program}
            />

        </div>
    )
}

export default Homepage