import React,{ Fragment } from 'react';
import Backdrop from './backdrop';
import './styles.scss'
import PhoneIcon from '../../images/phone.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

function Modal(props) {
    let {
        chapter
    } = props

    return (
        <Fragment>
            <Backdrop show={props.show} clicked={e=>props.modalClosed(e, 'chapter', null, false)} />
            <div
                id="modal-id"
                className="ModalChapter"
                style={{
                    display : props.show ? 'block' : 'none',
                    opacity : props.show ? 1 : 0,
                }}
            >
                <div className="modal-chapter-wrapper" >
                    <div className="modal-header-1235" >
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="fa-times-closed2414"
                            onClick={e=>props.modalClosed(e, 'chapter', null, false)}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        <span className="chapter-name-modal-1412" style={{ textTransform: 'capitalize' }}> Chapter { chapter?.chapter || '-' } </span>
                        <span className="chapter-address-modal-1412" >
                            { chapter?.alamat || '-' }
                        </span>
                        
                    </div>
                    <div style={{
                        marginTop: 'auto',
                    }}>
                        <div className="chapter-info-modal-1412">
                            Info dan pembelian PIN silahkan hubungi kontak dibawah ini
                        </div>
                        <div className="btn-custom-modal-332 top-876831"
                            style={{
                                display: "flex",
                                flexDirection: 'column',
                                padding: '10px 10px'
                            }}
                        >
                            {
                                chapter?.telepon?.map((phone, i)=>(
                                        <div key={i} style={{ display: 'flex', marginTop: i !== 0 && 5 }} >
                                            <img src={PhoneIcon} alt="icon" style={{ marginRight: 10 }} />
                                            <span>
                                                { phone || '-' }
                                            </span>
                                        </div>
                                )) || '-'
                            }
                        </div>
                        <div className="btn-custom-modal-332"
                            style={{
                                borderTop: '0.5px solid #DFF2FF',
                                borderBottomLeftRadius: '10px',
                                borderBottomRightRadius: '10px',
                                display: "flex",
                                flexDirection: 'column',
                                padding: '10px 10px'
                            }}
                        >
                            {
                                chapter?.sosialMedia?.map((medsos, i)=>(
                                    <div key={i} style={{ display: 'flex', marginTop: i !== 0 && 5 }}>
                                        { medsos || '-' }
                                    </div>
                                )) || '-'
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Modal;