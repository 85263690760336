import React, { useEffect, useState, useRef, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logoutPeserta, saveFinalisasi, changePassword, setFailSelection } from '../../store/actions/peserta'
import './styles.scss'
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import DescriptionIcon from '@material-ui/icons/Description';
import BinabudayaLogo from '../../images/homepage/binabud.svg'
import BinabudCircle from '../../images/homepage/binabud-circle.png'
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import LockIcon from '@material-ui/icons/Lock';
import { useHistory, Route , Switch, Link } from 'react-router-dom'
import Swal from 'sweetalert2'
// COMPONENT
import InputData from './inputData'
import DownloadBerkas from './downloadBerkas'
import UploadBerkas from './uploadBerkas'
import Informasi from './informasi'
import { useMediaQuery } from 'react-responsive'
import BannerAFS from '../../images/AFSHeader.png'
import BannerKAKEHASHI from '../../images/KAKEHASHIHeader.png'
import BannerYES from '../../images/YESHeader.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faFileUpload, faSignOutAlt, faCheckCircle, faInfoCircle, faShieldAlt, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import ModalChangePassword from '../../components/changePassword'

const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        backgroundColor: '#3598DC',
        minHeight: 64,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        minHeight: 64,
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarShiftClose:{
        minHeight: 64,
        marginLeft: 30,
        width: `calc(100vw - ${70}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarMobile:{
        minHeight: 64,
        width: `calc(100vw)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    popupTop:{
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '10px',
        lineHeight: '12px',
        color: '#666666',
        height: 34
    },
    popuplist:{
        height: 40
    },
    paperCustom:{
        zIndex: 99999
    }
}));

const MenuButtonActive = withStyles((theme) => ({
    root: {
        width: '90%',
        height: '50px',
        color: '#3598DC',
        backgroundColor: '#F1F9FF',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'flex-start',
        paddingLeft: '19px',
    },
}))(Button);

const MenuButtonCancel = withStyles((theme) => ({
    root: {
        width: '100%',
        height: '50px',
        color: 'red',
        backgroundColor: '#ffd6d6',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#ffd6d6',
            boxShadow: 'none',
        },
    },
}))(Button);

const MenuButtonFinalisasi = withStyles((theme) => ({
    root: {
        width: '90%',
        height: '40px',
        color: '#F1F9FF',
        backgroundColor: '#3598DC',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'flex-start',
        paddingLeft: '19px',
        '&:hover': {
            backgroundColor: '#5dace3',
            boxShadow: 'none',
        },
    },
}))(Button);

const MenuButton = withStyles((theme) => ({
    root: {
        width: '90%',
        height: '50px',
        color: '#C1C1C1',
        backgroundColor: 'none',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'flex-start',
        paddingLeft: '19px',
        '&:hover': {
            backgroundColor: 'none',
            color: '#C1C1C1',
            boxShadow: 'none',
        },
    },
}))(Button);

function Menu() {
    const mobile = useMediaQuery( { maxWidth: 599 } )
    const classes = useStyles();
    const History = useHistory()
    const Dispatch = useDispatch()
    const [open, setOpen] = useState(true);
    const anchorRef = useRef(null);
    const [openPopup, setOpenPopup] = useState(false);
    const gagalSeleksi = useSelector(state=> state.pesertaState.gagalSeleksi )
    const options = useSelector(state=> state.pesertaState.options )
    const profile = useSelector(state=>state.pesertaState.profile);
    const [modalToggle , setModalToggle] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const [password, setPassword] = useState('')
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [passwordOld, setPasswordOld] = useState('')
    const [showPasswordOld, setShowPasswordOld] = useState(false)
    const [click, setClick] = useState(false)
    const [error, setError] = useState('')
    const [berkasNotComplete, setBerkasNotComplete] = useState(true)
    const [activeStep, setActiveStep] = useState(1);

    useEffect(()=>{
        if (profile) {
            let counting = 0
            if (activeStep >= 7) {
                if (profile?.statusSeleksiChapter === true) {
                    Dispatch(setFailSelection('lulus seleksi'))
                    setActiveStep(8)
                }
            } else if (activeStep >= 6) {
                if (profile?.statusSeleksiInteraksiKelompok === true) {
                    setActiveStep(7)
                }
            } else if (activeStep >= 5) {
                if (profile?.statusSeleksiWawancara === true) {
                    setActiveStep(6)
                }
            } else if (activeStep >= 4) {
                if (profile?.statusSeleksiBerkas === true) {
                    setActiveStep(5)
                }
            } else if (activeStep >= 3){
                if (profile?.statusVerifikasiBerkas === true) {
                    setActiveStep(4)
                }
                if (profile?.suratPernyataanSekolah?.file) {
                    counting++
                }
                if (profile?.suratIzinOrangTua?.file) {
                    counting++
                } 
                if (profile?.suratRekomendasiKeluarga?.file) {
                    counting++
                } 
                if (profile?.suratRekomendasiSekolah?.file) {
                    counting++
                } 
                if (profile?.suratRekomendasiTemanDekat?.file) {
                    counting++
                } 
                if (profile?.raport?.file) {
                    counting++
                } 
                if (profile?.aktaKelahiran?.file) {
                    counting++
                } 
                if (profile?.skhunIjazah?.file) {
                    counting++
                } 
                if (profile?.sertifikatDll?.file) {
                    counting++
                }
                if (counting !== 9) {
                    setActiveStep(2)
                }
            } else if (activeStep <= 2) {
                if (profile?.suratPernyataanSekolah?.file) {
                    counting++
                }
                if (profile?.suratIzinOrangTua?.file) {
                    counting++
                } 
                if (profile?.suratRekomendasiKeluarga?.file) {
                    counting++
                } 
                if (profile?.suratRekomendasiSekolah?.file) {
                    counting++
                } 
                if (profile?.suratRekomendasiTemanDekat?.file) {
                    counting++
                } 
                if (profile?.raport?.file) {
                    counting++
                } 
                if (profile?.aktaKelahiran?.file) {
                    counting++
                } 
                if (profile?.skhunIjazah?.file) {
                    counting++
                } 
                if (profile?.sertifikatDll?.file) {
                    counting++
                }
                if (counting === 9) {
                    setBerkasNotComplete(true)
                    if (profile?.finalisasi === true) {
                        setActiveStep(3)
                    } else {
                        setActiveStep(2)
                    }
                } else {
                    setBerkasNotComplete(false)
                    setActiveStep(1)
                }
            }
        }
    },[profile, activeStep])

    const getStatus = (index, key) => {
        if (index === 0) {
            return true
        } else if (index === 2){
            if (profile?.finalisasi) {
                return true
            }
            return false
        } else if (index === 1){
            if (key === 'Surat Pernyataan Sekolah') {
                if (profile?.suratPernyataanSekolah?.file) {
                    return true
                } else if (options?.[profile?.program]?.uploadBerkas === false){
                    if (gagalSeleksi !== true) Dispatch(setFailSelection('tidak lengkap berkas'))
                    return 'gagal'
                } else {
                    return false
                }
            } else if (key === 'Surat Pernyataan Orang Tua') {
                if (profile?.suratIzinOrangTua?.file) {
                    return true
                } else if (options?.[profile?.program]?.uploadBerkas === false){
                    if (gagalSeleksi !== true) Dispatch(setFailSelection('tidak lengkap berkas'))
                    return 'gagal'
                } else {
                    return false
                }
            } else if (key === 'Surat Rekomendasi Lingkungan Rumah') {
                if (profile?.suratRekomendasiKeluarga?.file) {
                    return true
                } else if (options?.[profile?.program]?.uploadBerkas === false){
                    if (gagalSeleksi !== true) Dispatch(setFailSelection('tidak lengkap berkas'))
                    return 'gagal'
                }  else {
                    return false
                }
            } else if (key === 'Surat Rekomendasi Sekolah') {
                if (profile?.suratRekomendasiSekolah?.file) {
                    return true
                } else if (options?.[profile?.program]?.uploadBerkas === false){
                    if (gagalSeleksi !== true) Dispatch(setFailSelection('tidak lengkap berkas'))
                    return 'gagal'
                } else {
                    return false
                }
            } else if (key === 'Surat Rekomendasi Teman Dekat') {
                if (profile?.suratRekomendasiTemanDekat?.file) {
                    return true
                } else if (options?.[profile?.program]?.uploadBerkas === false){
                    if (gagalSeleksi !== true) Dispatch(setFailSelection('tidak lengkap berkas'))
                    return 'gagal'
                } else {
                    return false
                }
            } else if (key === 'Raport') {
                if (profile?.raport?.file) {
                    return true
                } else if (options?.[profile?.program]?.uploadBerkas === false){
                    if (gagalSeleksi !== true) Dispatch(setFailSelection('tidak lengkap berkas'))
                    return 'gagal'
                } else {
                    return false
                }
            } else if (key === 'Akta Kelahiran') {
                if (profile?.aktaKelahiran?.file) {
                    return true
                } else if (options?.[profile?.program]?.uploadBerkas === false){
                    if (gagalSeleksi !== true) Dispatch(setFailSelection('tidak lengkap berkas'))
                    return 'gagal'
                } else {
                    return false
                }
            } else if (key === 'SKHUN & Ijazah SMP') {
                if (profile?.skhunIjazah?.file) {
                    return true
                } else if (options?.[profile?.program]?.uploadBerkas === false){
                    if (gagalSeleksi !== true) Dispatch(setFailSelection('tidak lengkap berkas'))
                    return 'gagal'
                } else {
                    return false
                }
            } else if (key === 'Sertifikat dan lain-lain') {
                if (profile?.sertifikatDll?.file) {
                    return true
                } else if (options?.[profile?.program]?.uploadBerkas === false){
                    if (gagalSeleksi !== true) Dispatch(setFailSelection('tidak lengkap berkas'))
                    return 'gagal'
                } else {
                    return false
                }
            }
        } else if (index === 3){
            if (profile && activeStep >= 3) {
                if (profile?.statusVerifikasiBerkas === true) {
                    return true
                } else if (profile?.statusVerifikasiBerkas === false) {
                    if (gagalSeleksi !== true) Dispatch(setFailSelection(true))
                    return 'gagal'
                } else {
                    return false
                } 
            }
        } else if (index === 4){
            if (profile && activeStep >= 4) {
                if (profile?.statusSeleksiBerkas === true) {
                    return true
                } else if (profile?.statusSeleksiBerkas === false) {
                    if (gagalSeleksi !== true) Dispatch(setFailSelection(true))
                    return 'gagal'
                } else {
                    return false
                } 
            }
        } else if (index === 5){
            if (profile && activeStep >= 5) {
                if (profile?.statusSeleksiWawancara === true) {
                    return true
                } else if (profile?.statusSeleksiWawancara === false) {
                    if (gagalSeleksi !== true) Dispatch(setFailSelection(true))
                    return 'gagal'
                } else {
                    return false
                } 
            }
        } else if (index === 6){
            if (profile && activeStep >= 6) {
                if (profile?.statusSeleksiInteraksiKelompok === true) {
                    return true
                } else if (profile?.statusSeleksiInteraksiKelompok === false) {
                    if (gagalSeleksi !== true) Dispatch(setFailSelection(true))
                    return 'gagal'
                } else {
                    return false
                } 
            }
        } else if (index === 7){
            if (profile && activeStep >= 7) {
                if (profile?.statusSeleksiChapter === true) {
                    return true
                } else if (profile?.statusSeleksiChapter === false) {
                    if (gagalSeleksi !== true) Dispatch(setFailSelection(true))
                    return 'gagal'
                } else {
                    return false
                } 
            }
        }
    }

    useEffect(()=>{
        let data = JSON.parse(localStorage.getItem('options'))
        if (data) {
            setOpen(data.sidebar);
            if (!mobile) {
                if (data.sidebar) {
                    document.getElementById('parent-component').style.width="calc(100vw - 230px)"
                } else {
                    document.getElementById('parent-component').style.width="calc(100vw - 73px)"
                }
            } else if (mobile) {
                document.getElementById('parent-component').style.width="calc(100vw)"
            }
        }
    },[localStorage.getItem('options'), mobile, options, profile])

    const goTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    
    const handleDrawerClose = () => {
        let data = JSON.parse(localStorage.getItem('options'))
        if (open) {
            localStorage.setItem('options', JSON.stringify({...data, sidebar: false}))
            if (!mobile) {
                document.getElementById('parent-component').style.width="calc(100vw - 73px)"
            }
        } else {
            localStorage.setItem('options', JSON.stringify({...data, sidebar: true}))
            if (!mobile) {
                document.getElementById('parent-component').style.width="calc(100vw - 230px)"
            }
        }
        setOpen(!open);
    };

    const handleToggle = () => {
        setOpenPopup((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenPopup(false);
    };

    const handleLogout =(e, saveOut) => {
        if (e) handleClose(e)
        Dispatch(logoutPeserta(History, Swal, saveOut))
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const finalisasi = () => {
        const SwalBootstrap1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        const SwalBootstrap2 = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin-ok',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        if (berkasNotComplete === false) {
            return SwalBootstrap2.fire({
                title: 'Berkas Belum Lengkap',
                text: `Silahkan Lengkapi Upload Berkas`,
                icon: 'info',
                showCancelButton: false,
                confirmButtonText: 'OK',
                reverseButtons: true,
            }).then((result) => {
                History.push('/upload-berkas')
            })
        }
        SwalBootstrap1.fire({
            title: 'Apakah adik sudah yakin untuk memfinalisasi?',
            text: "Finalisasi tidak dapat dibatalkan, periksa kembali kelengkapan data adik",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya, Saya Yakin',
            cancelButtonText: 'Saya Belum Yakin',
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                if (options?.[profile?.program]?.finalisasi) {
                    SwalBootstrap2.fire({
                        title: 'Finalisasi Data Berhasil',
                        text: `Setelah seluruh proses selesai, silahkan tunggu pengumuman lebih lanjut dari chapter masing-masing. Kontak Chapter tertera pada halaman Informasi`,
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'OK',
                        cancelButtonText: '',
                        reverseButtons: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then((result) => {
                        if (result.value) {
                            Dispatch(saveFinalisasi(true, Swal, History, goTop, '/informasi'))
                        } else if (
                            /* Read more about handling dismissals below */
                            result.dismiss === Swal.DismissReason.cancel
                        ) {}
                    })
                } else {
                    SwalBootstrap2.fire({
                        title: 'Finalisasi Telah Ditutup',
                        text: "",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'OK',
                        cancelButtonText: '',
                        reverseButtons: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then((result) => {
                        if (result.value) {
                        } else if (
                            /* Read more about handling dismissals below */
                                result.dismiss === Swal.DismissReason.cancel
                        ) {}
                    })
                }
            } else if ( result.dismiss === Swal.DismissReason.cancel) {}
        })
    }

    const batalFinalisasi = () => {
        const SwalBootstrap1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        const SwalBootstrap2 = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin-ok',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        SwalBootstrap1.fire({
            title: 'Apakah adik sudah yakin untuk membatalkan finalisasi?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya, Saya Yakin',
            cancelButtonText: 'Saya Belum Yakin',
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                if (options?.[profile?.program]?.finalisasi) {
                    SwalBootstrap2.fire({
                        title: 'Batal Finalisasi Berhasil',
                        text: "Silahkan lakukan pengecekan data adik kembali",
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'OK',
                        cancelButtonText: '',
                        reverseButtons: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then((result) => {
                        if (result.value) {
                            Dispatch(saveFinalisasi(false, Swal, History, goTop, '/input'))
                        } else if (
                            /* Read more about handling dismissals below */
                                result.dismiss === Swal.DismissReason.cancel
                        ) {}
                    })
                } else {
                    SwalBootstrap2.fire({
                        title: 'Finalisasi Telah Ditutup',
                        text: "",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'OK',
                        cancelButtonText: '',
                        reverseButtons: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then((result) => {
                        if (result.value) {
                        } else if (
                            /* Read more about handling dismissals below */
                                result.dismiss === Swal.DismissReason.cancel
                        ) {}
                    })
                }
            } else if ( result.dismiss === Swal.DismissReason.cancel) {}
        })
    }

    const modalHandler = (e) => {
        if (e) {e.preventDefault();}
        resetPassword()
        setModalToggle(!modalToggle)
    }

    const passwordOldHandler = () => {
        setShowPasswordOld(!showPasswordOld)
    }

    const passwordHandler = () => {
        setShowPassword(!showPassword)
    }

    const passwordHandlerRepeat = () => {
        setShowPasswordRepeat(!showPasswordRepeat)
    }

    const handleChangePass = () => {
        setClick(true)
        if(passwordOld && password && passwordRepeat) {
            if (password !== passwordRepeat) {
                setError('Password tidak sama')
            } else {
                Dispatch(changePassword({password, passwordOld}, Swal, setError, resetPassword))
            }
        } else {
            setError('Lengkapi Persyaratan')
        }
    }

    const resetPassword = () => {
        setModalToggle(false)
        setShowPassword(false)
        setPassword('')
        setShowPasswordRepeat(false)
        setPasswordRepeat('')
        setClick(false)
        setError('')
        setPasswordOld('')
        setShowPasswordOld(false)
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: (open && !mobile),
                    [classes.appBarShiftClose]: (!open && !mobile),
                    [classes.appBarMobile]: mobile,
                })}
                style={{ zIndex: 999 }}
            >
                <Toolbar>
                    {
                        open ?
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerClose}
                            edge="start"
                        >
                            <MenuIcon />
                        </IconButton>
                        :
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerClose}
                            edge="start"
                        >
                            <MenuIcon />
                        </IconButton>
                    }
                    
                    <div className="appbar-title-wrapper" >
                        <span className="appbar-font-title" > Pastikan Anda mengisi seluruh data yang dibutuhkan agar bisa lanjut ke tahap berikutnya </span>
                        <div className="custom-popup" >
                            <span className="popup-welcome" > Selamat Datang </span>
                            <span
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                                style={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }}
                                >
                                { profile?.username }
                                <ExpandMoreIcon/>
                            </span>
                            <Popper open={openPopup} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper className={ classes.paperCustom } >
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList autoFocusItem={openPopup} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                    <MenuItem onClick={e=>modalHandler(e)} className={classes.popuplist} >
                                                        <LockIcon style={{ color: '#999999', width: 20, marginRight: 8 }}/>
                                                        <span className="text-menu-item-popup" > Ganti Password </span> 
                                                    </MenuItem>
                                                    <MenuItem onClick={e=>handleLogout(e)} className={classes.popuplist}>
                                                        <FontAwesomeIcon
                                                            icon={faSignOutAlt}
                                                            style={{ color: '#FF5579', fontSize: 17, marginLeft: 4, marginRight: 7 }}
                                                        />
                                                        <span className="text-menu-item-popup" style={{ color: '#FF5579' }}> Keluar </span> 
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </div>

                </Toolbar>
            </AppBar>
            {
                (
                    profile?.page >= 12
                    // gagalSeleksi === true ||
                    // gagalSeleksi === 'lulus seleksi' ||
                    // ((options?.finalisasi === false) && (profile?.finalisasi === false)) ||
                    // ((profile?.finalisasi === true) && (profile?.nomorUrut === '0000')) ||
                    // (activeStep === 1) ||
                    // (activeStep === 2 && (profile?.finalisasi === true)) ||
                    // (activeStep === 3 && (profile?.finalisasi === true)) ||
                    // (activeStep === 4 && (profile?.finalisasi === true)) ||
                    // (activeStep === 5 && (profile?.finalisasi === true)) ||
                    // (activeStep === 6 && (profile?.finalisasi === true)) ||
                    // (activeStep === 7 && (profile?.finalisasi === true))
                ) &&
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: (open && !mobile),
                        [classes.appBarShiftClose]: (!open && !mobile),
                        [classes.appBarMobile]: mobile,
                    })}
                    style={{
                        zIndex: 998,
                        marginTop: 62,
                        padding: '10px 24px',
                        background: gagalSeleksi === 'lulus seleksi' ? 'linear-gradient(90deg, #005c97 0%,#363795 100% )' : 'linear-gradient(90deg, #ee0979 0%,#ff6a00 100% )',
                        minHeight: '0px'
                    }}
                >
                    <div className="alert-info-2131249"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {
                            gagalSeleksi === true ?
                                `Mohon maaf adik gagal dalam seleksi Bina Antarbudaya Program ${profile?.program || ''}.`
                            :
                            gagalSeleksi === 'tidak lengkap berkas' ?
                                `Pendaftaran dan upload berkas telah ditutup`
                            :
                            gagalSeleksi === 'lulus seleksi' ?
                                `Selamat adik dinyatakan lulus seleksi Bina Antarbudaya Program ${profile?.program || ''}. `
                            :
                            (options?.[profile?.program]?.finalisasi === false) && (profile?.finalisasi === false) ?
                                "Batas pengisian data / finalisasi telah ditutup !"
                            :
                            ((options?.[profile?.program]?.uploadBerkas === false) && (profile?.finalisasi === true) && (gagalSeleksi === 'tidak lengkap berkas' && gagalSeleksi === true)) &&
                                "Batas upload berkas telah ditutup !"
                        }
                        {
                            gagalSeleksi === 'lulus seleksi' &&
                            <span>
                                <Link
                                    to={{ pathname: '/hasil-seleksi'}}
                                    target="_BLANK"
                                    style={{
                                        textDecoration: 'none',
                                        marginLeft: 'auto',
                                        cursor: 'pointer',
                                        color: 'white',
                                        textDecoration: 'underline',
                                        margin: '0px 5px'
                                    }}
                                >
                                    Klik di sini
                                </Link> untuk melihat hasil seleksi.
                            </span>
                        }
                        {
                            (gagalSeleksi !== 'tidak lengkap berkas' && gagalSeleksi !== true) &&
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                {
                                    gagalSeleksi
                                }
                            {
                                ((activeStep === 1 || activeStep === 2) && !profile?.finalisasi ) ?
                                "Silahkan lengkapi upload berkas adik & lakukan finalisasi" :
                                (activeStep === 3 && profile?.finalisasi === true) ?
                                "Berkas adik telah lengkap, selanjutnya silahkan tunggu berkas diverifikasi oleh chapter untuk mendapatkan nomor peserta" :
                                (activeStep === 4 && profile?.finalisasi === true) ?
                                <span>
                                    Selamat adik telah lulus verifikasi berkas.
                                </span> :
                                (activeStep === 5 && profile?.finalisasi === true) ?
                                <span>
                                    Selamat adik telah lulus seleksi berkas.
                                </span>:
                                (activeStep === 6 && profile?.finalisasi === true) ?
                                <span>
                                    Selamat adik telah lulus seleksi wawancara.
                                </span>:
                                (activeStep === 7 && profile?.finalisasi === true) &&
                                <span>
                                    Selamat adik telah lulus interaksi kelompok.
                                </span>
                            }
                            {
                                ((profile?.finalisasi === true)) &&
                                (
                                    activeStep === 4 ||
                                    activeStep === 5 ||
                                    activeStep === 6 ||
                                    activeStep === 7
                                ) &&
                                <span>
                                    Untuk informasi langkah selanjutnya silahkan hubungi atau kunjungi media sosial chapter adik masing-masing.
                                </span>
                            }
                            {
                                ((profile?.finalisasi === true)) &&
                                (
                                    activeStep === 4 ||
                                    activeStep === 5 ||
                                    activeStep === 6 ||
                                    activeStep === 7
                                ) &&
                                <span>
                                    Atau kunjungi media sosial instagram
                                    <Link
                                        to={{ pathname: 'https://www.instagram.com/binaantarbudaya'}}
                                        target="_BLANK"
                                        style={{
                                            textDecoration: 'none',
                                            marginLeft: 'auto',
                                            cursor: 'pointer',
                                            color: 'white',
                                            textDecoration: 'underline',
                                            margin: '0px 5px'
                                        }}
                                    >
                                        @binaantarbudaya
                                    </Link>
                                    untuk informasi resmi.
                                </span> 
                            }
                            </div>
                        }
                    </div>
                </AppBar>
            }

            <Drawer
                anchor={'left'}
                open={open}
                onClose={e=>handleDrawerClose()}
                variant={!mobile && "permanent"}
                className={
                    clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper:
                    clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                {
                    open ?
                        profile ?
                        profile.program === 'AFS' ?
                            <img src={BannerAFS} style={{ width: '100%' }} draggable={false} className="logo-binabudaya-sidebar" alt="Binabudaya-logo" /> :
                        profile ?
                        profile.program === 'KAKEHASHI' ?
                            <img src={BannerKAKEHASHI} style={{ width: '100%' }} draggable={false} className="logo-binabudaya-sidebar" alt="Binabudaya-logo" /> :
                        profile ?
                        profile.program === 'YES' &&
                            <img src={BannerYES} style={{ width: '100%' }} draggable={false} className="logo-binabudaya-sidebar" alt="Binabudaya-logo" />
                        :
                        <img src={BinabudayaLogo} style={{ width: '100%' }} className="logo-binabudaya-sidebar" alt="Binabudaya-logo" />
                        :
                        <img src={BinabudayaLogo} style={{ width: '100%' }} className="logo-binabudaya-sidebar" alt="Binabudaya-logo" />
                        :
                        <img src={BinabudayaLogo} style={{ width: '100%' }} className="logo-binabudaya-sidebar" alt="Binabudaya-logo" />
                        :
                    <img src={BinabudCircle} className="logo-binabudaya-circle-sidebar" alt="Binabudaya-logo" />
                }
                {
                    !open &&
                    <Divider />
                }
                <div className="wrapper-btn-list-menu" style={{ rowGap: 10 }} >
                    {
                        (profile?.page >= 12) ?
                        History.location.pathname === '/informasi' ? 
                        <MenuButtonActive
                            style={{
                                display: !open && 'flex',
                                justifyContent: !open && 'center',
                                alignItems: !open && 'center',
                                paddingLeft: !open && 0,
                                width: !open && '100%',
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                style={{ fontSize: 18, marginLeft: 5, marginRight: 5 }}
                            />
                            {
                                open &&
                                <span className="menu-list-font" >Informasi</span>
                            }
                        </MenuButtonActive>
                        :
                            <MenuButton
                                style={{
                                    display: !open && 'flex',
                                    justifyContent: !open && 'center',
                                    alignItems: !open && 'center',
                                    paddingLeft: !open && 0,
                                    width: !open && '100%',
                                }}
                                onClick={()=>History.push('/informasi')}
                            >
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    style={{ fontSize: 18, marginLeft: 5, marginRight: 5 }}
                                />
                                {
                                    open &&
                                    <span className="menu-list-font" >Informasi</span>
                                }
                            </MenuButton>
                        : ''
                    }
                    {
                        History.location.pathname === '/input' ?
                            <MenuButtonActive
                                style={{
                                    display: !open && 'flex',
                                    justifyContent: !open && 'center',
                                    alignItems: !open && 'center',
                                    paddingLeft: !open && 0,
                                    width: !open && '100%',
                                }}
                            >
                                <DescriptionIcon />
                                {
                                    open &&
                                    <span className="menu-list-font-active" >Input Data</span>
                                }
                            </MenuButtonActive>
                        :
                            <MenuButton
                                style={{
                                    display: !open && 'flex',
                                    justifyContent: !open && 'center',
                                    alignItems: !open && 'center',
                                    paddingLeft: !open && 0,
                                    width: !open && '100%',
                                }}
                                onClick={()=>History.push('/input')}
                            >
                                <DescriptionIcon />
                                {
                                    open &&
                                    <span className="menu-list-font-active" >Input Data</span>
                                }
                            </MenuButton>
                    }
                    {
                        (profile?.page >= 12) ?
                        History.location.pathname === '/download-berkas' ? 
                            <MenuButtonActive
                            style={{
                                display: !open && 'flex',
                                justifyContent: !open && 'center',
                                alignItems: !open && 'center',
                                paddingLeft: !open && 0,
                                width: !open && '100%',
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faFileDownload}
                                style={{ fontSize: 18, marginLeft: 5, marginRight: 5 }}
                            />
                            {
                                open &&
                                <span className="menu-list-font" >Download Berkas</span>
                            }
                        </MenuButtonActive>
                        :
                            <MenuButton
                                style={{
                                    display: !open && 'flex',
                                    justifyContent: !open && 'center',
                                    alignItems: !open && 'center',
                                    paddingLeft: !open && 0,
                                    width: !open && '100%',
                                }}
                                onClick={()=>History.push('/download-berkas')}
                            >
                                <FontAwesomeIcon
                                    icon={faFileDownload}
                                    style={{ fontSize: 18, marginLeft: 5, marginRight: 5 }}
                                />
                                {
                                    open &&
                                    <span className="menu-list-font" >Download Berkas</span>
                                }
                            </MenuButton>
                        : ''
                    }
                    {
                        (profile?.page >= 12) ?
                        History.location.pathname === '/upload-berkas' ? 
                            <MenuButtonActive
                                style={{
                                    display: !open && 'flex',
                                    justifyContent: !open && 'center',
                                    alignItems: !open && 'center',
                                    paddingLeft: !open && 0,
                                    width: !open && '100%',
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faFileUpload}
                                    style={{ fontSize: 18, marginLeft: 5, marginRight: 5 }}
                                />
                                {
                                    open &&
                                    <span className="menu-list-font" >Upload Berkas</span>
                                }
                            </MenuButtonActive>
                        :
                            <MenuButton
                                style={{
                                    display: !open && 'flex',
                                    justifyContent: !open && 'center',
                                    alignItems: !open && 'center',
                                    paddingLeft: !open && 0,
                                    width: !open && '100%',
                                }}
                                onClick={()=>History.push('/upload-berkas')}
                            >
                                <FontAwesomeIcon
                                    icon={faFileUpload}
                                    style={{ fontSize: 18, marginLeft: 5, marginRight: 5 }}
                                />
                                {
                                    open &&
                                    <span className="menu-list-font" >Upload Berkas</span>
                                }
                            </MenuButton>
                        : ''
                    }
                    {
                        (profile?.page >= 12) ?
                            (options?.[profile?.program]?.finalisasi === false) ?
                            <MenuButtonCancel
                                style={{
                                    display: !open && 'flex',
                                    justifyContent: !open && 'center',
                                    alignItems: !open && 'center',
                                    paddingLeft: !open && 0,
                                    width: !open && '100%',
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    style={{ fontSize: 18, marginLeft: 5, marginRight: 5 }}
                                />
                                {
                                    open &&
                                    <span className="menu-list-font" style={{ marginLeft: 10 }} >Finalisasi Ditutup</span>
                                }
                            </MenuButtonCancel>
                            :
                            !((options?.[profile?.program]?.finalisasi === false) || (profile?.finalisasi === true)) ?
                            <MenuButtonFinalisasi
                                style={{
                                    display: !open && 'flex',
                                    justifyContent: !open && 'center',
                                    alignItems: !open && 'center',
                                    paddingLeft: !open && 0,
                                    width: !open && '100%',
                                }}
                                onClick={finalisasi}
                            >
                                <FontAwesomeIcon
                                    icon={faShieldAlt}
                                    style={{ fontSize: 18, marginLeft: 5, marginRight: 5 }}
                                />
                                {
                                    open &&
                                    <span className="menu-list-font" >Finalisasi</span>
                                }
                            </MenuButtonFinalisasi>
                            :
                            <MenuButtonFinalisasi
                                style={{
                                    display: !open && 'flex',
                                    justifyContent: !open && 'center',
                                    alignItems: !open && 'center',
                                    paddingLeft: !open && 0,
                                    width: !open && '100%',
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    style={{ fontSize: 18, marginLeft: 5, marginRight: 5 }}
                                />
                                {
                                    open &&
                                    <span className="menu-list-font" >Terfinalisasi</span>
                                }
                            </MenuButtonFinalisasi>
                        : <Fragment/>
                    }
                </div>
                {
                    open ?
                    <div
                        style={{
                            marginTop: 'auto'
                        }}
                    >
                        {
                            ((options?.[profile?.program]?.finalisasi !== false) && (profile?.finalisasi)) ?
                            <MenuButtonCancel
                                style={{
                                    display: !open && 'flex',
                                    justifyContent: !open && 'center',
                                    alignItems: !open && 'center',
                                    paddingLeft: !open && 0,
                                    width: !open && '100%',
                                }}
                                onClick={batalFinalisasi}
                            >
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    style={{ fontSize: 18 }}
                                />
                                {
                                    open &&
                                    <span className="menu-list-font" >Batal Finalisasi</span>
                                }
                            </MenuButtonCancel>
                            : ''
                        }
                        <div className="drawer-footer" >
                            2023 © Yayasan Bina Antarbudaya
                        </div>
                    </div>
                    :
                    <div
                        style={{
                            marginTop: 'auto'
                        }}
                    >
                        {
                            (profile?.page >= 12) ?
                            <MenuButtonCancel
                                style={{
                                    display: !open && 'flex',
                                    justifyContent: !open && 'center',
                                    alignItems: !open && 'center',
                                    paddingLeft: !open && 0,
                                    width: !open && '100%',
                                }}
                                onClick={batalFinalisasi}
                            >
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    style={{ fontSize: 18 }}
                                />
                                {
                                    open &&
                                    <span className="menu-list-font" >Batal Finalisasi</span>
                                }
                            </MenuButtonCancel>
                            : ''
                        }
                        <div className="drawer-footer-small">
                                2023 ©<br/>Yayasan<br/>Bina<br/>Antarbudaya
                        </div>
                    </div>
                }
            </Drawer>
            
            <div className="parents-component-seleksi" id="parent-component"
                style={{
                    paddingTop:
                    (
                        gagalSeleksi === true ||
                        gagalSeleksi === 'lulus seleksi' ||
                        (options?.[profile?.program]?.finalisasi === false) && (profile?.finalisasi === false) ||
                        ((profile?.finalisasi === true) && (profile?.nomorUrut === '0000')) ||
                        activeStep === 1 ||
                        activeStep === 2 ||
                        activeStep === 3 ||
                        activeStep === 4 ||
                        activeStep === 5 ||
                        activeStep === 6 ||
                        activeStep === 7
                    ) && 120
                }}
            >
                <Switch>
                    <Route path="/upload-berkas" component={UploadBerkas} />
                    <Route path="/informasi" render={props => <Informasi activeStep={activeStep} getStatus={getStatus} />}/>
                    <Route path="/download-berkas" component={DownloadBerkas} />
                    <Route path="/input" render={props =><InputData handleLogout={handleLogout} />}/>
                </Switch>
            </div>
            
            <ModalChangePassword
                username={profile?.username || '-'}
                show={modalToggle}
                modalClosed={modalHandler}
                children={"modal"}
                passwordHandler={passwordHandler}
                passwordHandlerRepeat={passwordHandlerRepeat}
                password={password}
                setPassword={setPassword}
                click={click}
                showPassword={showPassword}
                passwordRepeat={passwordRepeat}
                showPasswordRepeat={showPasswordRepeat}
                setPasswordRepeat={setPasswordRepeat}
                error={error}
                setError={setError}
                handleChangePass={handleChangePass}
                passwordOld={passwordOld}
                setPasswordOld={setPasswordOld}
                passwordOldHandler={passwordOldHandler}
                showPasswordOld={showPasswordOld}
            />
        </div>
    );
}

export default Menu