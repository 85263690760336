import React, { useEffect } from 'react';
import { Route ,  Switch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { getChapter, getProfile, getOptions } from './store/actions/peserta'
import Swal from 'sweetalert2'
import ReactGa from 'react-ga'
import './App.css';

// IMPORT SCREENS
import Homepage from './screens/Homepage'
import AktivasiPin from './screens/AktivasiPin'
import LoginPeserta from './screens/LoginPeserta'
import KartuPeserta from './screens/PDF/KartuPeserta'
import Essay from './screens/PDF/Essay'
import BerkasLengkap from './screens/PDF/BerkasLengkap'
import RegisterPeserta from './screens/RegistrasiPeserta'
import LupaPasswordPeserta from './screens/LupaPasswordPeserta'
import PasswordBaruPeserta from './screens/PasswordBaruPeserta'
import GantiPasswordPeserta from './screens/GantiPasswordPeserta'
import Menu from './screens/Menu'
import HasilSeleksi from './screens/HasilSeleksi'
import PDF from './screens/PDF'
import VerificationEmail from './screens/VerificationEmail'
import PasswordBaruVerification from './screens/PasswordBaruVerification'

function App() {
  const Dispatch = useDispatch()
  const History = useHistory()

  useEffect(()=>{
    Dispatch(getChapter())
    Dispatch(getProfile(History, Swal))
    Dispatch(getOptions())
    ReactGa.initialize('G-CKWZV3S8ZX')
    ReactGa.pageview(window.location.pathname + window.location.search)
  },[])

  return (
    <div className="App">
      <Switch>
        <Route path="/verification/:program/:id" component={VerificationEmail} />
        <Route path="/hasil-seleksi" component={HasilSeleksi} />
        <Route path="/pdf" component={PDF} />
        <Route path="/upload-berkas" component={Menu} />
        <Route path="/download-berkas/kartu-peserta" component={KartuPeserta} />
        <Route path="/download-berkas/essay" component={Essay} />
        <Route path="/download-berkas/pendaftaran-lengkap" component={BerkasLengkap} />
        <Route path="/informasi" component={Menu} />
        <Route path="/download-berkas" component={Menu} />
        <Route path="/input" component={Menu} />
        <Route path="/ganti-password" component={GantiPasswordPeserta} />
        <Route path="/lupa-password/password-baru" component={PasswordBaruPeserta} />
        <Route path="/lupa-password/:id/:username" component={PasswordBaruVerification} />
        <Route path="/lupa-password" component={LupaPasswordPeserta} />
        <Route path="/daftar" component={RegisterPeserta} />
        <Route path="/masuk" component={LoginPeserta} />
        <Route path="/aktivasi-pin" component={AktivasiPin} />
        <Route path="/" component={Homepage} />
      </Switch>
    </div>
  );
}

export default App;
