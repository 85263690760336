import React, { useEffect, useState } from 'react'
import './styles.scss'
import BannerAFS from '../../images/AFSHeader.png'
import BannerKAKEHASHI from '../../images/KAKEHASHIHeader.png'
import BannerYES from '../../images/YESHeader.png'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2'
import { loginPeserta } from '../../store/actions/peserta'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import AppBar from '@material-ui/core/AppBar';

const ActivationButton = withStyles((theme) => ({
    root: {
        marginTop: '50px',
        width: '100%',
        height: '44px',
        color: '#ffffff',
        backgroundColor: '#3598DC',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#2f88c6',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
    },
}))(Button);

const ActivationButtonGrey = withStyles((theme) => ({
    root: {
        marginTop: '20px',
        width: '100%',
        height: '44px',
        color: '#3598DC',
        border: '1px solid #3598DC',
        backgroundColor: ' rgba(53, 152, 220, 0.15)',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(53, 152, 220, 0.15)',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
    },
}))(Button);


const GreenCheckbox = withStyles({
    root: {
        color: '#D1D1D1',
        '&$checked': {
            color: '#3598DC',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles({
    label:{
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#666666',
    }
});

const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}

export default function Login({match}) {
    const query = useQuery()
    const classes = useStyles();
    const History = useHistory()
    const Dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [passwordValid, setPasswordValid] = useState(false)
    const [checked, setChecked] = useState(false)
    const [click, setClick] = useState(false)
    const [program, setProgram] = useState(null)
    const [sendData, setSendData] = useState({
        email : '',
        password : ''
    })
    const isChrome = navigator.userAgent.indexOf("Chrome") != -1;
    const isFirefox = navigator.userAgent.indexOf("Firefox") != -1;

    useEffect(()=>{
        if (query.get('program')) {
            setProgram((query.get('program')).toUpperCase())
        } else {
            History.replace('/')
        }
    },[query.get('program')])

    const HandleRegister = () => {
        History.push('/daftar?program='+(program).toLowerCase())
        goTop()
    }

    const goTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleChangeChecked = (event) => {
        setChecked(event.target.checked);
    };

    const handleChange = (key, value) => {
        switch (key) {
            case 'email':
                if (passwordValid) {
                    setPasswordValid(false)
                }
                setSendData({
                    ...sendData,
                    email : value,
                })
                break;
                case 'password':
                    setSendData({
                        ...sendData,
                        password : value,
                    })
                    break;
            default:
                break;
        }
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const SignIn = (e) => {
        if (e) e.preventDefault()
        setClick(true)

        const ToastMid = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        
        if (sendData.email && sendData.password) {
            if (validateEmail(sendData.email)) {
                Dispatch(loginPeserta(History, {...sendData, program }, Swal, checked))
            } else {
                ToastMid.fire({
                    icon: 'warning',
                    title: `Isi email dengan benar`,
                })
                setPasswordValid(true)
            }
        } else {
            ToastMid.fire({
                icon: 'warning',
                title: `Isi email dan password`,
            })
        }
    }

    return (
        <div className="login-container-wrapper">
<AppBar
                position="fixed"
                style={{
                    zIndex: 998,
                    padding: '10px 24px',
                    background: 'linear-gradient(90deg, #005c97 0%,#363795 100% )',
                    minHeight: '0px'
                }}
            >
                <div
                    className="alert-info-2131249"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    Gunakan hanya browser Google Chrome atau Firefox untuk mengakses Online Registration ini
                </div>
            </AppBar>
            {
                program &&
                    program === 'YES' ? 
                        <img src={BannerYES} alt="logo" className="bannerYES-new2021" draggable={false} />
                    :
                    program === 'AFS' ? 
                        <img src={BannerAFS} alt="logo" className="bannerAFS-new2021" draggable={false} />
                    :
                    program === 'KAKEHASHI' && 
                        <img src={BannerKAKEHASHI} alt="logo" className="bannerKAKEHASHI-new2021" draggable={false} />
            }
            <span className="login-font-top" >
                Silahkan Masuk ke Akun adik
            </span>
            <form>
            <div className="login-container-card" >
                <span className="card-font-text-username" >Email</span>
                <input
                    type="email"
                    onChange={e=>handleChange('email',e.target.value)}
                    placeholder="Email"
                    className="login-input-theme"
                    style={{ border: (click && (!sendData.email || passwordValid)) && '1px solid red' }}
                />
                <span className="card-font-text-password" style={{ marginTop: 20 }}>Password</span>
                <div className="password-wraper" >
                    <input 
                        onChange={e=>handleChange('password',e.target.value)}
                        type={!showPassword ? 'password' : 'text'}
                        placeholder="Password"
                        className="login-input-theme"
                        style={{ border: (click && !sendData.password) && '1px solid red' }}
                    />
                    {
                        showPassword ?
                        <FontAwesomeIcon
                            icon={faEye}
                            className="eye-custom"
                            style={{ color: '#3c98dc' }}
                            onClick={() => handleShowPassword()}
                        />
                            :
                        <FontAwesomeIcon
                            icon={faEye}
                            className="eye-custom"
                            onClick={() => handleShowPassword()}
                        />
                    }
                </div>
                <div className="bottom-container-wrapper" >
                    <FormControlLabel
                        className={classes.label}
                        value="ingat"
                        onChange={handleChangeChecked}
                        control={<GreenCheckbox />}
                        label="Ingat Saya"
                        labelPlacement="end"
                    />
                    <span className="lupa-password-font blue-color" onClick={()=>History.push('/lupa-password')}>
                        Lupa Password?
                    </span>
                </div>
                <ActivationButton type="submit" onClick={(e)=>SignIn(e)} >Masuk</ActivationButton>
                {
                    program &&
                    (program !== 'AFS') &&
                    <ActivationButtonGrey type="submit" onClick={HandleRegister} >Buat Akun</ActivationButtonGrey>
                }
            </div>
            </form>
        </div>
    )
}