import React, { Component } from 'react'
import './styles.scss'

// EDITOR
import { Editor } from 'react-draft-wysiwyg';

class Print extends Component {
    constructor (props) {
        super(props)
        this.state = {
            data : [
                '1','1','1','1','1','1','1','1','1','1',
                '1','1','1','1','1','1','1','1','1','1'
            ]
        }
    }

    PerPage = (data) => {
        let temporary = []
        for (let i = 0; i < Math.ceil(data.length/16); i++) {
            temporary.push('1')
        }
        return temporary
    }

    BagiperPage = (data) => {
        let count = 1
        let bank = []
        let sand = []
        for (let i = 0; i < data.length -1; i++) {
            if (count === 16) {
                sand.push(data[i])
                count = 0
                bank.push(sand)
                sand = []
            } else {
                sand.push(data[i])
                count++
            }
        }
        if (sand.length !== 0) {
            bank.push(sand)
        }
        return bank
    }

    render () {
        return(
            <div className="container-print-pin" >
                {
                    this.PerPage(this.state.data).map((num,i)=>(
                    <div className="container-page-pin" key={i}>
                        <div className="wrapping-card-pin" >
                            {
                                this.BagiperPage(this.state.data)[0].map((num,i)=>(
                                    <div className="pin-card" key={i} style={{ marginTop: i !== 0 || i !== 1 ? null : 0 }} >
                                        <div className="pin-card-header" >
                                            PIN Pendaftaran Seleksi Bina Antarbudaya Tahun 2021
                                        </div>
                                        <div className="pin-content" >
                                            <div className="pin-left-content" >
                                                <span className="pin-style">
                                                PNUMBWUPPZHMIQLU
                                                </span>
                                                <span className="exp-pin-grey" style={{ marginTop: 7 }} >
                                                Berlaku Sampai:
                                                </span>
                                                <span className="exp-pin-black" style={{ marginTop: 1 }}>
                                                Kamis, 30 Juli 2020, Pukul 23:59:59 WIB
                                                </span>
                                                <div className="footer-pin-wraper" >
                                                    <span className="pin-footer">
                                                        http://seleksi.bina-antarbudaya.or.id/
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="pin-right-content" >
                                                <img
                                                    src="https://miro.medium.com/fit/c/336/336/1*uIGov_Y3AQloj0ooZfYfHw.png"
                                                    alt="logo"
                                                    className="logo-binabud-cirle"
                                                />
                                                <span className="pin-chapter-name" >
                                                    Chapter Bandung
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                    ))
                }
            </div>
        )
    }
}

export default Print