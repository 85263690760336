import React, { useEffect } from "react";
import "./styles.scss";
import BannerAFS from "../../images/AFSpdf.png";
import BannerKAKEHASHI from "../../images/KAKEHASHIpdf.png";
import Swal from "sweetalert2";
import BannerYES from "../../images/YESpdf.png";

// EDITOR
import { useSelector } from "react-redux";

function BerkasLengkap() {
  const profile = useSelector((state) => state.pesertaState.profile);
  const essay = useSelector((state) => state.filterState.essay);
  const chapterList = useSelector((state) => state.chapterState.chapter);
  const propinsiDB = useSelector((state) => state.daerahState.propinsi);
  const kabupatenDB = useSelector((state) => state.daerahState.kabupaten);

  useEffect(() => {
    if (profile?.dataPribadi && essay && chapterList) {
      document.title = `Berkas Pendaftaran Lengkap ${getMyName(
        profile?.dataPribadi
      ).toUpperCase()}.pdf`;
      Swal.close();
      setTimeout(() => {
        window.print();
        window.close();
      }, 2000);
    }
  }, [profile?.dataPribadi, essay, chapterList]);

  useEffect(() => {
    Swal.showLoading();
  }, []);

  const getChapterCode = (chapterList, chapter) => {
    if (chapterList && chapter) {
      let temp = chapterList?.filter((a) => a._id === chapter)?.[0];
      return temp?.code || "-";
    }
  };

  const getBanner = (program) => {
    let banner = "";
    if (program) {
      if (program === "AFS") {
        banner = BannerAFS;
      } else if (program === "YES") {
        banner = BannerYES;
      } else if (program === "KAKEHASHI") {
        banner = BannerKAKEHASHI;
      }
    }
    return banner;
  };

  const getClassNameBanner = (program) => {
    let className = "";
    if (program) {
      if (program === "AFS") {
        className = "container-header-print-afs-new";
      } else if (program === "YES") {
        className = "container-header-print";
      } else if (program === "KAKEHASHI") {
        className = "container-header-print";
      }
    }
    return className;
  };

  const getMyName = (dataPribadi) => {
    let namaDepan;
    let namaTengah;
    let namaBelakang;
    if (dataPribadi) {
      namaDepan = dataPribadi.namaDepan;
      namaTengah = dataPribadi.namaTengah;
      namaBelakang = dataPribadi.namaBelakang;
    }
    return `${namaDepan ? namaDepan + " " : ""}${
      namaTengah ? namaTengah + " " : ""
    }${namaBelakang ? namaBelakang + " " : ""}`;
  };

  const getFirstName = (dataPribadi) => {
    let namaDepan;
    if (dataPribadi) {
      namaDepan = dataPribadi.namaDepan;
    }
    return `${namaDepan ? namaDepan : "-"}`;
  };

  const getMidName = (dataPribadi) => {
    let namaTengah;
    if (dataPribadi) {
      namaTengah = dataPribadi.namaTengah;
    }
    return `${namaTengah ? namaTengah : "-"}`;
  };

  const getLastName = (dataPribadi) => {
    let namaBelakang;
    if (dataPribadi) {
      namaBelakang = dataPribadi.namaBelakang;
    }
    return `${namaBelakang ? namaBelakang : "-"}`;
  };

  const getSchool = (school) => {
    return `${school ? school : "-"}`;
  };

  const getTypeSchool = (type) => {
    return `${type ? type : "-"}`;
  };

  const getAddressBorn = (dataPribadi) => {
    let tempat;
    let tanggal;
    if (dataPribadi) {
      tempat = dataPribadi.tempatLahir;
      tanggal = dataPribadi.tanggalLahir;
    }
    return `${tempat ? tempat : "-"}, ${tanggal ? getDate(tanggal) : "-"}`;
  };

  const getDate = (value) => {
    if (value) {
      let start = new Date(value).getDate();
      let startMonth = new Date(value).getMonth();
      let monthStart = "";
      let yearResult = new Date(value).getFullYear();
      switch (startMonth) {
        case 0:
          monthStart = "Januari";
          break;
        case 1:
          monthStart = "Februari";
          break;
        case 2:
          monthStart = "Maret";
          break;
        case 3:
          monthStart = "April";
          break;
        case 4:
          monthStart = "Mei";
          break;
        case 5:
          monthStart = "Juni";
          break;
        case 6:
          monthStart = "Juli";
          break;
        case 7:
          monthStart = "Agustus";
          break;
        case 8:
          monthStart = "September";
          break;
        case 9:
          monthStart = "Oktober";
          break;
        case 10:
          monthStart = "November";
          break;
        case 11:
          monthStart = "Desember";
          break;
        default:
          monthStart = "";
          break;
      }

      return start + " " + monthStart + " " + yearResult;
    }
  };

  const getDateFinalisasi = (value) => {
    if (value) {
      let start = new Date(value).getDate();
      let startMonth = new Date(value).getMonth();
      let monthStart = "";
      let yearResult = new Date(value).getFullYear();
      switch (startMonth) {
        case 0:
          monthStart = "Januari";
          break;
        case 1:
          monthStart = "Februari";
          break;
        case 2:
          monthStart = "Maret";
          break;
        case 3:
          monthStart = "April";
          break;
        case 4:
          monthStart = "Mei";
          break;
        case 5:
          monthStart = "Juni";
          break;
        case 6:
          monthStart = "Juli";
          break;
        case 7:
          monthStart = "Agustus";
          break;
        case 8:
          monthStart = "September";
          break;
        case 9:
          monthStart = "Oktober";
          break;
        case 10:
          monthStart = "November";
          break;
        case 11:
          monthStart = "Desember";
          break;
        default:
          monthStart = "";
          break;
      }

      return " " + start + " " + monthStart + " " + yearResult;
    }
  };

  const getYear = (value) => {
    if (value) {
      let yearResult = new Date(value).getFullYear();
      return yearResult;
    } else {
      return "-";
    }
  };

  const getMail = (dataPribadi) => {
    let mail;
    if (dataPribadi) {
      mail = dataPribadi.email;
    }
    return `${mail ? mail : "-"}`;
  };

  const getPhone = (dataPribadi) => {
    let phone;
    if (dataPribadi) {
      phone = dataPribadi.telepon;
    }
    return `${phone ? phone : "-"}`;
  };

  const getAddressPlace = (dataPribadi) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (dataPribadi) {
      if (dataPribadi.propinsi) {
        if (propinsiDB.filter((a) => a.id === dataPribadi.propinsi)[0]) {
          propinsi = propinsiDB.filter((a) => a.id === dataPribadi.propinsi)[0]
            .propinsi;
        }
      }
      if (kabupatenDB[dataPribadi.propinsi]) {
        if (
          kabupatenDB[dataPribadi.propinsi].filter(
            (a) => a.id === dataPribadi.kabupaten
          )[0]
        ) {
          kota = kabupatenDB[dataPribadi.propinsi].filter(
            (a) => a.id === dataPribadi.kabupaten
          )[0].kabupaten;
        }
      }
      alamat = dataPribadi.alamat;
      kecamatan = dataPribadi.kecamatan;
      kodepos = dataPribadi.kodepos;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : ""}`;
  };

  const getGender = (dataPribadi) => {
    let kelamin;
    if (dataPribadi) {
      kelamin = dataPribadi.kelamin;
    }
    return `${kelamin ? kelamin : "-"}`;
  };

  const getHeight = (dataPribadi) => {
    let tinggi;
    if (dataPribadi) {
      tinggi = dataPribadi.tinggi;
    }
    return `${tinggi ? tinggi : "-"}`;
  };

  const getWeight = (dataPribadi) => {
    let berat;
    if (dataPribadi) {
      berat = dataPribadi.berat;
    }
    return `${berat ? berat : "-"}`;
  };

  const getBlood = (dataPribadi) => {
    let goldar;
    if (dataPribadi) {
      goldar = dataPribadi.goldar;
    }
    return `${goldar ? goldar : "-"}`;
  };

  const getCitizenOfNation = (dataPribadi) => {
    let wargaNegara;
    if (dataPribadi) {
      wargaNegara = dataPribadi.wargaNegara;
    }
    return `${wargaNegara ? wargaNegara : "-"}`;
  };

  const getReligion = (dataPribadi) => {
    let agama;
    if (dataPribadi) {
      if (dataPribadi.agama) {
        agama =
          dataPribadi.agama === "protestan" ? "kristen" : dataPribadi.agama;
      }
    }
    return `${agama ? agama : "-"}`;
  };

  const getFamilyValue = (obj, key) => {
    let result;
    if (obj) {
      result = obj[key];
    }
    return `${result ? result : "-"}`;
  };

  const getFamily2Value = (obj, key, key2) => {
    let result;
    let result2;
    if (obj) {
      result = obj[key];
      result2 = obj[key2];
    }
    return `${result ? result : "-"}, ${result2 ? result2 : "-"}`;
  };

  const getFamilyTitle = (obj, key, key2) => {
    let result;
    let result2;
    if (obj) {
      result = obj[key];
      result2 = obj[key2];
    }
    return `Anak ke ${result ? result : "-"} dari ${
      result2 ? result2 : "-"
    } Bersaudara`;
  };

  const getFamilyTable = (obj, key) => {
    let result = [
      {
        nama: "-",
        tanggalLahir: null,
        sekolahPekerjaan: "-",
      },
    ];
    if (obj) {
      result = obj[key];
    }
    return result;
  };

  const getMapelTable = (obj, key) => {
    let result = [
      {
        kelas: "-",
        semester: "-",
        agama: "-",
        pancasila: "-",
        indonesia: "-",
        matematika: "-",
        sejarah: "-",
        inggris: "-",
      },
    ];
    if (obj) {
      result = obj[key];
    }
    return result;
  };

  const getMapelTableSMP = (obj, key) => {
    let result = [
      {
        kelas: "-",
        semester: "-",
        agama: "-",
        pancasila: "-",
        indonesia: "-",
        matematika: "-",
        ipa: "-",
        ips: "-",
        inggris: "-",
      },
    ];
    if (obj) {
      result = obj[key];
    }
    return result;
  };

  const convertToRoman = (num) => {
    if (num) {
      let roman = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
      };
      var str = "";
      for (var i of Object.keys(roman)) {
        var q = Math.floor(num / roman[i]);
        num -= q * roman[i];
        str += i.repeat(q);
      }
      return str ? str : "-";
    } else {
      return "-";
    }
  };

  const getAddressSchool = (riwayatPendidikan) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (riwayatPendidikan) {
      if (riwayatPendidikan.propinsiSekolah) {
        if (
          propinsiDB.filter(
            (a) => a.id === riwayatPendidikan.propinsiSekolah
          )[0]
        ) {
          propinsi = propinsiDB.filter(
            (a) => a.id === riwayatPendidikan.propinsiSekolah
          )[0].propinsi;
        }
      }
      if (kabupatenDB[riwayatPendidikan.propinsiSekolah]) {
        if (
          kabupatenDB[riwayatPendidikan.propinsiSekolah].filter(
            (a) => a.id === riwayatPendidikan.kabupatenSekolah
          )[0]
        ) {
          kota = kabupatenDB[riwayatPendidikan.propinsiSekolah].filter(
            (a) => a.id === riwayatPendidikan.kabupatenSekolah
          )[0].kabupaten;
        }
      }
      alamat = riwayatPendidikan.alamatSekolah;
      kecamatan = riwayatPendidikan.kecamatanSekolah;
      kodepos = riwayatPendidikan.kodeposSekolah;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : ""}`;
  };

  const getPengetahuanBahasa = (obj, key) => {
    let result = [
      {
        bahasa: "-",
        lama: "-",
      },
    ];
    if (obj) {
      result = obj[key];
    }
    return result;
  };

  const getKegiatan = (obj, key) => {
    let result = [
      {
        nama: "-",
        jenis: "-",
        jabatan: "-",
        tahun: null,
      },
    ];
    if (obj) {
      result = obj[key];
    }
    return result;
  };

  const getRiwayatKunjungan = (obj, key) => {
    let result = [
      {
        negara: "-",
        tahun: null,
        rangka: "-",
      },
    ];
    if (obj) {
      result = obj[key];
    }
    return result;
  };

  const getNegara = (obj) => {
    let result = [];
    if (obj) {
      result = obj;
    }
    return result;
  };

  const getAddressLS = (dataPribadi) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (dataPribadi) {
      if (dataPribadi.propinsiLingkunganSekolah) {
        if (
          propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiLingkunganSekolah
          )[0]
        ) {
          propinsi = propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiLingkunganSekolah
          )[0].propinsi;
        }
      }
      if (kabupatenDB[dataPribadi.propinsiLingkunganSekolah]) {
        if (
          kabupatenDB[dataPribadi.propinsiLingkunganSekolah].filter(
            (a) => a.id === dataPribadi.kabupatenLingkunganSekolah
          )[0]
        ) {
          kota = kabupatenDB[dataPribadi.propinsiLingkunganSekolah].filter(
            (a) => a.id === dataPribadi.kabupatenLingkunganSekolah
          )[0].kabupaten;
        }
      }
      alamat = dataPribadi.alamatLingkunganSekolah;
      kecamatan = dataPribadi.kecamatanLingkunganSekolah;
      kodepos = dataPribadi.kodeposLingkunganSekolah;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : ""}`;
  };

  const getAddressLS2 = (dataPribadi) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (dataPribadi) {
      if (dataPribadi.propinsiKerjaLingkunganSekolah) {
        if (
          propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiKerjaLingkunganSekolah
          )[0]
        ) {
          propinsi = propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiKerjaLingkunganSekolah
          )[0].propinsi;
        }
      }
      if (kabupatenDB[dataPribadi.propinsiKerjaLingkunganSekolah]) {
        if (
          kabupatenDB[dataPribadi.propinsiKerjaLingkunganSekolah].filter(
            (a) => a.id === dataPribadi.kabupatenKerjaLingkunganSekolah
          )[0]
        ) {
          kota = kabupatenDB[dataPribadi.propinsiKerjaLingkunganSekolah].filter(
            (a) => a.id === dataPribadi.kabupatenKerjaLingkunganSekolah
          )[0].kabupaten;
        }
      }
      alamat = dataPribadi.alamatKerjaLingkunganSekolah;
      kecamatan = dataPribadi.kecamatanKerjaLingkunganSekolah;
      kodepos = dataPribadi.kodeposKerjaLingkunganSekolah;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : ""}`;
  };

  const getAddressLR = (dataPribadi) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (dataPribadi) {
      if (dataPribadi.propinsiLingkunganRumah) {
        if (
          propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiLingkunganRumah
          )[0]
        ) {
          propinsi = propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiLingkunganRumah
          )[0].propinsi;
        }
      }
      if (kabupatenDB[dataPribadi.propinsiLingkunganRumah]) {
        if (
          kabupatenDB[dataPribadi.propinsiLingkunganRumah].filter(
            (a) => a.id === dataPribadi.kabupatenLingkunganRumah
          )[0]
        ) {
          kota = kabupatenDB[dataPribadi.propinsiLingkunganRumah].filter(
            (a) => a.id === dataPribadi.kabupatenLingkunganRumah
          )[0].kabupaten;
        }
      }
      alamat = dataPribadi.alamatLingkunganRumah;
      kecamatan = dataPribadi.kecamatanLingkunganRumah;
      kodepos = dataPribadi.kodeposLingkunganRumah;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : ""}`;
  };

  const getAddressLR2 = (dataPribadi) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (dataPribadi) {
      if (dataPribadi.propinsiKerjaLingkunganRumah) {
        if (
          propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiKerjaLingkunganRumah
          )[0]
        ) {
          propinsi = propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiKerjaLingkunganRumah
          )[0].propinsi;
        }
      }
      if (kabupatenDB[dataPribadi.propinsiKerjaLingkunganRumah]) {
        if (
          kabupatenDB[dataPribadi.propinsiKerjaLingkunganRumah].filter(
            (a) => a.id === dataPribadi.kabupatenKerjaLingkunganRumah
          )[0]
        ) {
          kota = kabupatenDB[dataPribadi.propinsiKerjaLingkunganRumah].filter(
            (a) => a.id === dataPribadi.kabupatenKerjaLingkunganRumah
          )[0].kabupaten;
        }
      }
      alamat = dataPribadi.alamatKerjaLingkunganRumah;
      kecamatan = dataPribadi.kecamatanKerjaLingkunganRumah;
      kodepos = dataPribadi.kodeposKerjaLingkunganRumah;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : ""}`;
  };

  const getAddressTD = (dataPribadi) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (dataPribadi) {
      if (dataPribadi.propinsiTemanDekat) {
        if (
          propinsiDB.filter((a) => a.id === dataPribadi.propinsiTemanDekat)[0]
        ) {
          propinsi = propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiTemanDekat
          )[0].propinsi;
        }
      }
      if (kabupatenDB[dataPribadi.propinsiTemanDekat]) {
        if (
          kabupatenDB[dataPribadi.propinsiTemanDekat].filter(
            (a) => a.id === dataPribadi.kabupatenTemanDekat
          )[0]
        ) {
          kota = kabupatenDB[dataPribadi.propinsiTemanDekat].filter(
            (a) => a.id === dataPribadi.kabupatenTemanDekat
          )[0].kabupaten;
        }
      }
      alamat = dataPribadi.alamatTemanDekat;
      kecamatan = dataPribadi.kecamatanTemanDekat;
      kodepos = dataPribadi.kodeposTemanDekat;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : ""}`;
  };

  return (
    <div className="container-print" style={{ boxSizing: "border-box" }}>
      {/* COVER */}
      <div className="container-page" style={{ maxHeight: "100vh" }}>
        <img
          src={getBanner(profile && profile.program)}
          alt="header"
          className={getClassNameBanner(profile && profile.program)}
        />
        <div className="page1-container">
          <span className="page1-font1" style={{ textTransform: "uppercase" }}>
            TRANSKRIP FORMULIR PENDAFTARAN SELEKSI PROGRAM{" "}
            {profile && profile.program === "KAKEHASHI"
              ? "ASIA KAKEHASHI PROJECT"
              : profile && profile.program}
          </span>
          <img
            src={profile?.pasFoto}
            className="container-header-print-image-page1"
            alt="header"
          />
          <span className="page1-name">
            {getMyName(profile && profile.dataPribadi)}
          </span>
          <span className="page1-no-name">
            YBA/YP25-26/
            {getChapterCode(chapterList, profile && profile.chapter)}/
            {profile && profile.program === "KAKEHASHI"
              ? "MXT"
              : profile && profile.program}
            /
            {profile &&
            profile.nomorUrut !== "0000" &&
            profile.statusVerifikasiBerkas === true
              ? profile.nomorUrut
              : "-"}
          </span>
          <div
            className="divider-page1-pdf"
            style={{ marginTop: 38, marginBottom: 20 }}
          />
          <div className="page1-custom-bottom-content">
            <div
              className="page1-custom-container"
              style={{ alignItems: "flex-start" }}
            >
              <span className="page1-custom-blue">Pilihan Program</span>
              <span className="page1-custom-black" style={{ marginTop: 12 }}>
                {profile?.program === "KAKEHASHI"
                  ? "Asia Kakehashi Project"
                  : profile?.program}
              </span>
            </div>
            <div
              className="page1-custom-container"
              style={{ alignItems: "center" }}
            >
              <span className="page1-custom-blue">Asal Sekolah</span>
              <span className="page1-custom-black" style={{ marginTop: 12 }}>
                {getSchool(
                  profile &&
                    profile.riwayatPendidikan &&
                    profile.riwayatPendidikan.namaSMA
                )}
              </span>
              <div className="info-person-btn-fake" style={{ marginTop: 14 }}>
                {getTypeSchool(
                  profile &&
                    profile.riwayatPendidikan &&
                    profile.riwayatPendidikan.jenisSekolah
                )}
              </div>
            </div>
            <div
              className="page1-custom-container"
              style={{ alignItems: "flex-end" }}
            >
              <span className="page1-custom-blue">Chapter</span>
              <span className="page1-custom-black" style={{ marginTop: 12 }}>
                {getChapterCode(chapterList, profile && profile.chapter)}
              </span>
            </div>
          </div>
          <div
            className="divider-page1-pdf"
            style={{ marginTop: 20, marginBottom: 0 }}
          />
        </div>
      </div>
      {/* DATA PRIBADI */}
      <div className="container-page" style={{ maxHeight: "100vh" }}>
        <div
          className="divider-page2-pdf"
          style={{ marginTop: 0, marginBottom: 15 }}
        />
        <div className="font-header-page2">DATA PRIBADI</div>
        <div className="page2-row-new" style={{ marginTop: 15 }}>
          <div className="page2-row-left-new">Nama Depan</div>
          <div
            className="page2-row-right-new"
            style={{ textTransform: "uppercase" }}
          >
            {getFirstName(profile?.dataPribadi)}
          </div>
        </div>
        <div className="page2-row-new">
          <div className="page2-row-left-new">Nama Tengah</div>
          <div
            className="page2-row-right-new"
            style={{ textTransform: "uppercase" }}
          >
            {getMidName(profile?.dataPribadi)}
          </div>
        </div>
        <div className="page2-row-new">
          <div className="page2-row-left-new">Nama Belakang</div>
          <div
            className="page2-row-right-new"
            style={{ textTransform: "uppercase" }}
          >
            {getLastName(profile?.dataPribadi)}
          </div>
        </div>
        <div className="page2-row-new">
          <div className="page2-row-left-new">Tempat dan Tanggal Lahir</div>
          <div
            className="page2-row-right-new"
            style={{ textTransform: "capitalize" }}
          >
            {getAddressBorn(profile?.dataPribadi)}
          </div>
        </div>
        <div className="page2-row-new">
          <div className="page2-row-left-new">Alamat E-mail</div>
          <div className="page2-row-right-new">
            {getMail(profile?.dataPribadi)}
          </div>
        </div>
        <div className="page2-row-new">
          <div className="page2-row-left-new">Nomor Ponsel</div>
          <div className="page2-row-right-new">
            {getPhone(profile?.dataPribadi)}
          </div>
        </div>
        <div className="page2-row-new">
          <div className="page2-row-left-new">Alamat Lengkap</div>
          <div className="page2-row-right-new">
            {getAddressPlace(profile?.dataPribadi)}
          </div>
        </div>
        <div className="page2-row-new">
          <div className="page2-row-left-new">Jenis Kelamin</div>
          <div
            className="page2-row-right-new"
            style={{ textTransform: "capitalize" }}
          >
            {getGender(profile?.dataPribadi)}
          </div>
        </div>
        <div className="page2-row-new">
          <div className="page2-row-left-new">Tinggi Badan</div>
          <div className="page2-row-right-new">
            {getHeight(profile?.dataPribadi)}
          </div>
        </div>
        <div className="page2-row-new">
          <div className="page2-row-left-new">Berat Badan</div>
          <div className="page2-row-right-new">
            {getWeight(profile?.dataPribadi)}
          </div>
        </div>
        <div className="page2-row-new">
          <div className="page2-row-left-new">Golongan Darah</div>
          <div className="page2-row-right-new">
            {getBlood(profile?.dataPribadi)}
          </div>
        </div>
        <div className="page2-row-new">
          <div className="page2-row-left-new">Kewarganegaraan</div>
          <div
            className="page2-row-right-new"
            style={{ textTransform: "uppercase" }}
          >
            {getCitizenOfNation(profile?.dataPribadi)}
          </div>
        </div>
        <div className="page2-row-new">
          <div className="page2-row-left-new">Agama</div>
          <div
            className="page2-row-right-new"
            style={{ textTransform: "uppercase" }}
          >
            {getReligion(profile?.dataPribadi)}
          </div>
        </div>

        <div className="font-header-page2" style={{ marginTop: 30 }}>PILIHAN PROGRAM</div>
        <div className="page2-row-new" style={{ marginTop: 15 }}>
          <div className="page2-row-left-new">Program</div>
          <div
            className="page2-row-right-new"
            style={{ textTransform: "uppercase" }}
          >
            {getFamilyValue(profile?.pilihanProgram, "program")}
          </div>
        </div>
        <div className="page2-row-new" style={{ marginTop: 15 }}>
          <div className="page2-row-left-new">
            Dimana Adik Mengetahui tentang Program <b>{
            profile?.program === "KAKEHASHI" ?
              "Asia Kakehashi Project" :
              profile?.program === 'YES' ?
                `KL-YES` :
              profile?.program ?
                profile?.program.toUpperCase() :
                "-"
          }</b>
          </div>
          <div
            className="page2-row-right-new"
            style={{ textTransform: "uppercase" }}
          >
            {getFamilyValue(profile?.pilihanProgram, "optionProgram")}
            {
              getFamilyValue(profile?.pilihanProgram, "optionProgram") === 'Lainnya' ?
              `  -  ${getFamilyValue(profile?.pilihanProgram, "optionProgramLainnya")}` : ""
            }
          </div>
        </div>
      </div>
      {/* KELUARGA */}
      <div className="container-page" style={{ maxHeight: "100vh" }}>
        <div
          className="divider-page2-pdf"
          style={{ marginTop: 0, marginBottom: 15 }}
        />
        <div className="font-header-page2" style={{ marginBottom: 15 }}>
          KELUARGA
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 15 }}>
          <div className="font-header-page3">Ayah</div>
          <div className="page2-row-new" style={{ marginTop: 10 }}>
            <div className="page2-row-left-new">Nama Lengkap</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile?.infoKeluarga, "namaAyah")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Pendidikan Terakhir</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile && profile.infoKeluarga,
                "pendidikanAyah"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Pekerjaan</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "pekerjaanAyah")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Jabatan/Pangkat/Golongan</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "jabatanAyah")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Alamat E-mail</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "emailAyah")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Nomor Ponsel</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "teleponAyah")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Nama dan Alamat Kantor</div>
            <div className="page2-row-right-new">
              {getFamily2Value(
                profile && profile.infoKeluarga,
                "namaKantorAyah",
                "alamatKantorAyah"
              )}
            </div>
          </div>
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 15 }}>
          <div className="font-header-page3">Ibu</div>
          <div className="page2-row-new" style={{ marginTop: 10 }}>
            <div className="page2-row-left-new">Nama Lengkap</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "namaIbu")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Pendidikan Terakhir</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "pendidikanIbu")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Pekerjaan</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "pekerjaanIbu")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Jabatan/Pangkat/Golongan</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "jabatanIbu")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Alamat E-mail</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "emailIbu")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Nomor Ponsel</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "teleponIbu")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Nama dan Alamat Kantor</div>
            <div className="page2-row-right-new">
              {getFamily2Value(
                profile && profile.infoKeluarga,
                "namaKantorIbu",
                "alamatKantorIbu"
              )}
            </div>
          </div>
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 0 }}>
          <div className="font-header-page3">Wali</div>
          <div className="page2-row-new" style={{ marginTop: 10 }}>
            <div className="page2-row-left-new">Nama Lengkap</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "namaWali")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Hubungan dengan adik</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "hubunganWali")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Pendidikan Terakhir</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile && profile.infoKeluarga,
                "pendidikanWali"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Alamat E-mail</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "emailWali")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Nomor Ponsel</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "teleponWali")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Alamat Rumah</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile && profile.infoKeluarga,
                "alamatRumahWali"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Pekerjaan</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "pekerjaanWali")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Jabatan/Pangkat/Golongan</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "jabatanWali")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Nama dan Alamat Kantor</div>
            <div className="page2-row-right-new">
              {getFamily2Value(
                profile && profile.infoKeluarga,
                "namaKantorWali",
                "alamatKantorWali"
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container-page" style={{ maxHeight: "100vh" }}>
        <div className="wrapper-content-86831" style={{ marginBottom: 15 }}>
          <div className="font-header-page3">Kakak dan adik</div>
          <div
            className="page2-row-new"
            style={{ marginTop: 5, border: "none" }}
          >
            <span className="page2-row-left" style={{ color: "#000000" }}>
              {getFamilyTitle(
                profile && profile.infoKeluarga,
                "anakKe",
                "dariAnak"
              )}
            </span>
          </div>
          <table className="table-style-custom-pdf">
            <tr>
              <th className="table-header-pdf">Nama Saudara</th>
              <th className="table-header-pdf">Tanggal Lahir</th>
              <th className="table-header-pdf">Sekolah/Pekerjaan</th>
            </tr>
            {getFamilyTable(profile && profile.infoKeluarga, "saudara").map(
              (person) =>
                person && (
                  <tr style={{}}>
                    <td className="table-td-pdf">
                      {person ? (person.nama ? person.nama : "-") : "-"}
                    </td>
                    <td className="table-td-pdf">
                      {person
                        ? person.tanggalLahir
                          ? getDate(person.tanggalLahir)
                          : "-"
                        : "-"}
                    </td>
                    <td className="table-td-pdf">
                      {person
                        ? person.sekolahPekerjaan
                          ? person.sekolahPekerjaan
                          : "-"
                        : "-"}
                    </td>
                  </tr>
                )
            )}
          </table>
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 15 }}>
          <div className="font-header-page3">
            Adakah di antara keluarga besar adik yang pernah mengikuti program
            pertukaran yang diselenggarakan oleh Bina Antarbudaya?
          </div>
          <div className="page2-row-new" style={{ marginTop: 10 }}>
            <div className="page2-row-left-new">Nama</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "alumniNama")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Hubungan dengan Peserta</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile && profile.infoKeluarga,
                "alumniHubungan"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Program</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "alumniProgram")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">
              Tujuan (Sending)/Asal (Hosting)
            </div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile && profile.infoKeluarga,
                "alumniTujuanAsal"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Alamat Sekarang</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.infoKeluarga, "alumniAlamat")}
            </div>
          </div>
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 0 }}>
          <div className="font-header-page3">
            Pernahkan adik atau Keluarga adik Berpartisipasi Dalam Kegiatan Bina
            Antarbudaya Sebelumnya?
          </div>
          <div className="page2-row-new" style={{ marginTop: 10 }}>
            <div className="page2-row-left-new">Kegiatan</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile && profile.infoKeluarga,
                "alumniKegiatan"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Tahun</div>
            <div className="page2-row-right-new">
              {getYear(
                profile &&
                  profile.infoKeluarga &&
                  profile.infoKeluarga.alumniTahun
              )}
            </div>
          </div>
        </div>
      </div>
      {/* RIWAYAT PENDIDIKAN */}
      <div className="container-page" style={{ maxHeight: "100vh" }}>
        <div
          className="divider-page2-pdf"
          style={{ marginTop: 0, marginBottom: 15 }}
        />
        <div className="font-header-page2" style={{ marginBottom: 15 }}>
          RIWAYAT PENDIDIKAN
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 15 }}>
          <div className="font-header-page3">SMA/SMK/MA/Pesantren</div>
          <div className="page2-row-new" style={{ marginTop: 10 }}>
            <div className="page2-row-left-new">Jenis Sekolah</div>
            <div
              className="page2-row-right-new"
              style={{ textTransform: "uppercase" }}
            >
              {getFamilyValue(
                profile && profile.riwayatPendidikan,
                "jenisSekolah"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Nama Sekolah</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.riwayatPendidikan, "namaSMA")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Alamat Sekolah</div>
            <div className="page2-row-right-new">
              {getAddressSchool(profile && profile.riwayatPendidikan)}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Telepon</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile && profile.riwayatPendidikan,
                "teleponSekolah"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Faks</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile && profile.riwayatPendidikan,
                "faksSekolah"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Tahun Masuk</div>
            <div className="page2-row-right-new">
              {getYear(
                profile &&
                  profile.riwayatPendidikan &&
                  profile.riwayatPendidikan.tahunMasukSekolah
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Tahun Keluar</div>
            <div className="page2-row-right-new">
              {getYear(
                profile &&
                  profile.riwayatPendidikan &&
                  profile.riwayatPendidikan.tahunKeluarSekolah
              )}
            </div>
          </div>
          <div
            className="page2-row-new"
            style={{ flexDirection: "column", border: "none" }}
          >
            <div
              className="page2-row-left-new"
              style={{ maxWidth: "100%", minWidth: "100%" }}
            >
              Data Prestasi SMA/SMK/MA/Pesantren (Skala 0-100)
            </div>
            <div
              className="page2-row-right-new"
              style={{ marginTop: 8, width: "100%" }}
            >
              <table className="table-style-custom-pdf">
                <tr>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Kelas
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Semester
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Pendidikan Agama
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Pancasila dan Kewarganegaraan
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Bahasa Indonesia
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Matematika
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Sejarah Indonesia
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Bahasa Inggris
                  </th>
                </tr>
                {getMapelTable(
                  profile && profile.riwayatPendidikan,
                  "prestasiSekolah"
                ).map(
                  (mapel) =>
                    mapel && (
                      <tr>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel
                            ? mapel.kelas
                              ? convertToRoman(mapel.kelas)
                              : "-"
                            : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel
                            ? mapel.semester
                              ? convertToRoman(mapel.semester)
                              : "-"
                            : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel ? (mapel.agama ? mapel.agama : "-") : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel
                            ? mapel.pancasila
                              ? mapel.pancasila
                              : "-"
                            : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel
                            ? mapel.indonesia
                              ? mapel.indonesia
                              : "-"
                            : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel
                            ? mapel.matematika
                              ? mapel.matematika
                              : "-"
                            : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel ? (mapel.sejarah ? mapel.sejarah : "-") : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel ? (mapel.inggris ? mapel.inggris : "-") : "-"}
                        </td>
                      </tr>
                    )
                )}
              </table>
            </div>
          </div>
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 0 }}>
          <div className="font-header-page3">SMP/MTs</div>
          <div className="page2-row-new" style={{ marginTop: 10 }}>
            <div className="page2-row-left-new">Nama Sekolah</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.riwayatPendidikan, "namaSMP")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Tahun Ijazah</div>
            <div className="page2-row-right-new">
              {getYear(
                profile &&
                  profile.riwayatPendidikan &&
                  profile.riwayatPendidikan.tahunIjazahSMP
              )}
            </div>
          </div>
          <div
            className="page2-row-new"
            style={{ flexDirection: "column", border: "none" }}
          >
            <div
              className="page2-row-left-new"
              style={{ maxWidth: "100%", minWidth: "100%" }}
            >
              Data Prestasi SMP/MTs (Skala 0-100)
            </div>
            <div
              className="page2-row-right-new"
              style={{ marginTop: 8, width: "100%" }}
            >
              <table className="table-style-custom-pdf">
                <tr>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Kelas
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Semester
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Pendidikan Agama
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Pancasila dan Kewarganegaraan
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Bahasa Indonesia
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Matematika
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Ilmu Pengetahuan Alam
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Ilmu Pengetahuan Sosial
                  </th>
                  <th
                    className="table-header-pdf"
                    style={{ textAlign: "center" }}
                  >
                    Bahasa Inggris
                  </th>
                </tr>
                {getMapelTableSMP(
                  profile && profile.riwayatPendidikan,
                  "prestasiSMP"
                ).map(
                  (mapel) =>
                    mapel && (
                      <tr>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel
                            ? mapel.kelas
                              ? convertToRoman(mapel.kelas)
                              : "-"
                            : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel
                            ? mapel.semester
                              ? convertToRoman(mapel.semester)
                              : "-"
                            : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel ? (mapel.agama ? mapel.agama : "-") : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel
                            ? mapel.pancasila
                              ? mapel.pancasila
                              : "-"
                            : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel
                            ? mapel.indonesia
                              ? mapel.indonesia
                              : "-"
                            : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel
                            ? mapel.matematika
                              ? mapel.matematika
                              : "-"
                            : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel ? (mapel.ipa ? mapel.ipa : "-") : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel ? (mapel.ips ? mapel.ips : "-") : "-"}
                        </td>
                        <td
                          className="table-td-pdf"
                          style={{ textAlign: "center" }}
                        >
                          {mapel ? (mapel.inggris ? mapel.inggris : "-") : "-"}
                        </td>
                      </tr>
                    )
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="container-page" style={{ maxHeight: "100vh" }}>
        <div className="wrapper-content-86831" style={{ marginBottom: 15 }}>
          <div className="font-header-page3">SD/MI</div>
          <div className="page2-row-new" style={{ marginTop: 10 }}>
            <div className="page2-row-left-new">Nama Sekolah</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.riwayatPendidikan, "namaSD")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Tahun Ijazah</div>
            <div className="page2-row-right-new">
              {getYear(
                profile &&
                  profile.riwayatPendidikan &&
                  profile.riwayatPendidikan.tahunIjazahSD
              )}
            </div>
          </div>
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 15 }}>
          <div className="font-header-page3">Pengetahuan Bahasa</div>
          {getPengetahuanBahasa(
            profile && profile.riwayatPendidikan,
            "pengetahuanBahasa"
          ).map((data, i) => (
            <div className="page2-row-new" style={{ marginTop: i === 0 && 10 }}>
              <div className="page2-row-left-new">
                {data ? (data.bahasa ? data.bahasa : "-") : "-"}
              </div>
              <div className="page2-row-right-new">
                {data ? (data.lama ? data.lama : "-") : "-"}
              </div>
            </div>
          ))}
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 0 }}>
          <div className="font-header-page3">Pelajaran Favorit & Cita-Cita</div>
          <div className="page2-row-new" style={{ marginTop: 10 }}>
            <div className="page2-row-left-new">Mata Pelajaran Favorit</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile && profile.riwayatPendidikan,
                "pelajaranFavorit"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Cita-Cita</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile && profile.riwayatPendidikan, "citaCita")}
            </div>
          </div>
        </div>
      </div>
      {/* RIWAYAT KEGIATAN & PRESTASI */}
      <div className="container-page" style={{ maxHeight: "100vh" }}>
        <div
          className="divider-page2-pdf"
          style={{ marginTop: 0, marginBottom: 15 }}
        />
        <div className="font-header-page2" style={{ marginBottom: 15 }}>
          RIWAYAT KEGIATAN & PRESTASI
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 10 }}>
          <div className="font-header-page3">Kegiatan</div>
          <div
            className="page2-row-new"
            style={{ marginTop: 5, border: "none" }}
          >
            <span className="page2-row-left" style={{ color: "#000000" }}>
              Kegiatan Pernah dan Sedang Diikuti
            </span>
          </div>
          <table className="table-style-custom-pdf">
            <tr>
              <th className="table-header-pdf">Kegiatan</th>
              <th className="table-header-pdf">Jenis Kegiatan</th>
              <th className="table-header-pdf">Jabatan</th>
              <th className="table-header-pdf">Tahun</th>
            </tr>
            {getKegiatan(profile && profile.riwayatKegiatan, "kegiatan").map(
              (person) =>
                person && (
                  <tr style={{}}>
                    <td className="table-td-pdf">
                      {person ? (person.nama ? person.nama : "-") : "-"}
                    </td>
                    <td className="table-td-pdf">
                      {person ? (person.jenis ? person.jenis : "-") : "-"}
                    </td>
                    <td className="table-td-pdf">
                      {person ? (person.jabatan ? person.jabatan : "-") : "-"}
                    </td>
                    <td className="table-td-pdf">
                      {person
                        ? person.tahun
                          ? getYear(person.tahun)
                          : "-"
                        : "-"}
                    </td>
                  </tr>
                )
            )}
          </table>
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 0 }}>
          <div
            className="page2-row-new"
            style={{ marginTop: 5, border: "none" }}
          >
            <span className="page2-row-left" style={{ color: "#000000" }}>
              Prestasi yang Pernah di Raih
            </span>
          </div>
          <table className="table-style-custom-pdf">
            <tr>
              <th className="table-header-pdf">Kejuaraan</th>
              <th className="table-header-pdf">Tingkat</th>
              <th className="table-header-pdf">Prestasi</th>
              <th className="table-header-pdf">Tahun</th>
            </tr>
            {getKegiatan(profile && profile.riwayatKegiatan, "prestasi").map(
              (person) =>
                person && (
                  <tr style={{}}>
                    <td className="table-td-pdf">
                      {person
                        ? person.kejuaraan
                          ? person.kejuaraan
                          : "-"
                        : "-"}
                    </td>
                    <td className="table-td-pdf">
                      {person ? (person.tingkat ? person.tingkat : "-") : "-"}
                    </td>
                    <td className="table-td-pdf">
                      {person ? (person.prestasi ? person.prestasi : "-") : "-"}
                    </td>
                    <td className="table-td-pdf">
                      {person
                        ? person.tahun
                          ? getYear(person.tahun)
                          : "-"
                        : "-"}
                    </td>
                  </tr>
                )
            )}
          </table>
        </div>
      </div>
      {/* RIWAYAT PERJALANAN KE LUAR NEGERI */}
      <div className="container-page" style={{ maxHeight: "100vh" }}>
        <div
          className="divider-page2-pdf"
          style={{ marginTop: 0, marginBottom: 15 }}
        />
        <div className="font-header-page2" style={{ marginBottom: 15 }}>
          RIWAYAT PERJALANAN KE LUAR NEGERI
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 10 }}>
          <div
            className="page2-row-new"
            style={{ marginTop: 5, border: "none" }}
          >
            <span
              className="page2-row-left"
              style={{ color: "#000000", maxWidth: "100%", minWidth: "100%" }}
            >
              Pernahkan adik Melawat/Berpergian Dalam Jangka Pendek ke Luar
              Negeri?
            </span>
          </div>
          <table className="table-style-custom-pdf">
            <tr>
              <th className="table-header-pdf">Ke Mana?</th>
              <th className="table-header-pdf">Kapan? </th>
              <th className="table-header-pdf">Dalam Rangka Apa?</th>
            </tr>
            {getRiwayatKunjungan(
              profile && profile.riwayatPerjalanan,
              "kunjungan"
            ).map(
              (person, i) =>
                person && (
                  <tr key={i} style={{}}>
                    <td className="table-td-pdf">
                      {person ? (person.negara ? person.negara : "-") : "-"}
                    </td>
                    <td className="table-td-pdf">
                      {person
                        ? person.tahun
                          ? getYear(person.tahun)
                          : "-"
                        : "-"}
                    </td>
                    <td className="table-td-pdf">
                      {person ? (person.rangka ? person.rangka : "-") : "-"}
                    </td>
                  </tr>
                )
            )}
          </table>
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 0 }}>
          <div
            className="page2-row-new"
            style={{ marginTop: 5, border: "none" }}
          >
            <span
              className="page2-row-left"
              style={{ color: "#000000", maxWidth: "100%", minWidth: "100%" }}
            >
              Pernahkan adik Tinggal di Luar Negeri?
            </span>
          </div>
          <table className="table-style-custom-pdf">
            <tr>
              <th className="table-header-pdf">Negara</th>
              <th className="table-header-pdf">Dari Tahun</th>
              <th className="table-header-pdf">Sampai Tahun</th>
            </tr>
            {getRiwayatKunjungan(
              profile && profile.riwayatPerjalanan,
              "tinggal"
            ).map(
              (person, i) =>
                person && (
                  <tr key={i} style={{}}>
                    <td className="table-td-pdf">
                      {person ? (person.negara ? person.negara : "-") : "-"}
                    </td>
                    <td className="table-td-pdf">
                      {person
                        ? person.dariTahun
                          ? getYear(person.dariTahun)
                          : "-"
                        : "-"}
                    </td>
                    <td className="table-td-pdf">
                      {person
                        ? person.sampaiTahun
                          ? getYear(person.sampaiTahun)
                          : "-"
                        : "-"}
                    </td>
                  </tr>
                )
            )}
          </table>
        </div>
      </div>
      {/* NEGARA TUJUAN */}
      <div className="container-page" style={{ maxHeight: "100vh" }}>
        <div
          className="divider-page2-pdf"
          style={{ marginTop: 0, marginBottom: 15 }}
        />
        <div className="font-header-page2" style={{ marginBottom: 15 }}>
          PILIHAN NEGARA TUJUAN
        </div>
        <div className="page2-row-new" style={{ marginTop: 10 }}>
          <div className="page2-row-left-new">Tujuan Negara</div>
          <div className="page2-row-right-new">
            {getNegara(profile?.pilihanNegara).map(
              (data, i) =>
                data && (
                  <span
                    className="page2-row-right"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      textTransform: "capitalize",
                    }}
                  >
                    {profile?.program === "AFS" && `${i + 1}.`}{" "}
                    {data ? data : "-"}
                  </span>
                )
            )}
          </div>
        </div>
      </div>
      {/* REKOMENDASI */}
      <div className="container-page" style={{ maxHeight: "100vh" }}>
        <div
          className="divider-page2-pdf"
          style={{ marginTop: 0, marginBottom: 15 }}
        />
        <div className="font-header-page2" style={{ marginBottom: 15 }}>
          REKOMENDASI
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 15 }}>
          <div className="font-header-page3">Lingkungan Sekolah</div>
          <div className="page2-row-new" style={{ marginTop: 10 }}>
            <div className="page2-row-left-new">Nama</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile?.rekomendasi,
                "namaLingkunganSekolah"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Alamat</div>
            <div className="page2-row-right-new">
              {getAddressLS(profile?.rekomendasi)}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Telepon</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile?.rekomendasi,
                "teleponLingkunganSekolah"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Pekerjaan</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile?.rekomendasi,
                "pekerjaanLingkunganSekolah"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Alamat Pekerjaan</div>
            <div className="page2-row-right-new">
              {getAddressLS2(profile?.rekomendasi)}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Hubungan</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile?.rekomendasi,
                "hubunganLingkunganSekolah"
              )}
            </div>
          </div>
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 15 }}>
          <div className="font-header-page3">
            Lingkungan Rumah/Organisasi di Luar Sekolah
          </div>
          <div className="page2-row-new" style={{ marginTop: 10 }}>
            <div className="page2-row-left-new">Nama</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile?.rekomendasi,
                "namaLingkunganRumah"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Alamat</div>
            <div className="page2-row-right-new">
              {getAddressLR(profile?.rekomendasi)}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Telepon</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile?.rekomendasi,
                "teleponLingkunganRumah"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Pekerjaan</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile?.rekomendasi,
                "pekerjaanLingkunganRumah"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Alamat Pekerjaan</div>
            <div className="page2-row-right-new">
              {getAddressLR2(profile?.rekomendasi)}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Hubungan</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile?.rekomendasi,
                "hubunganLingkunganRumah"
              )}
            </div>
          </div>
        </div>
        <div className="wrapper-content-86831" style={{ marginBottom: 15 }}>
          <div className="font-header-page3">Teman Dekat</div>
          <div className="page2-row-new" style={{ marginTop: 10 }}>
            <div className="page2-row-left-new">Nama</div>
            <div className="page2-row-right-new">
              {getFamilyValue(profile?.rekomendasi, "namaTemanDekat")}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Alamat</div>
            <div className="page2-row-right-new">
              {getAddressTD(profile?.rekomendasi)}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Telepon</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile?.rekomendasi,
                "teleponTemanDekat"
              )}
            </div>
          </div>
          <div className="page2-row-new">
            <div className="page2-row-left-new">Hubungan</div>
            <div className="page2-row-right-new">
              {getFamilyValue(
                profile?.rekomendasi,
                "hubunganTemanDekat"
              )}
            </div>
          </div>
        </div>
      </div>
      {/* KEPRIBADIAN */}
      <div className="container-page" style={{ maxHeight: "100vh" }}>
        <div
          className="divider-page2-pdf"
          style={{ marginTop: 0, marginBottom: 15 }}
        />
        <div className="font-header-page2" style={{ marginTop: 10 }}>
          MOTIVATIONAL STATEMENTS
        </div>
        <div
          className="page2-row-new"
          style={{ marginTop: 10, flexDirection: "column" }}
        >
          <div
            className="page2-row-left-new"
            style={{ minWidth: "100%", maxWidth: "100%" }}
          >
            <span style={{fontStyle: "italic"}}><u>{profile?.motivasi?.file || '-'}</u></span>
          </div>
        </div>

        <div className="font-header-page2" style={{ marginTop: 10 }}>
          ESSAY
        </div>
        <div
          className="page2-row-new"
          style={{ marginTop: 10, flexDirection: "column" }}
        >
          <div
            className="page2-row-left-new"
            style={{ minWidth: "100%", maxWidth: "100%" }}
          >
            <span style={{fontStyle: "italic"}}><u>{profile?.essay?.file || '-'}</u></span>
          </div>
        </div>

        <div className="font-header-page2">
          KEPRIBADIAN
        </div>
        <div
          className="page2-row-new"
          style={{ marginTop: 10, flexDirection: "column" }}
        >
          <div
            className="page2-row-left-new"
            style={{ minWidth: "100%", maxWidth: "100%" }}
          >
            <span style={{fontStyle: "italic"}}><u>{profile?.kepribadian?.file || '-'}</u></span>
          </div>
        </div>

        <div className="ttd-wrapper-pdf" style={{ marginTop: "auto" }}>
          <span className="ttd-wrapper-pdf-txt" style={{ textAlign: "left" }}>
            Saya yang bertanda tangan di bawah ini menyatakan bahwa informasi
            yang terdapat pada dokumen ini adalah adalah benar dan apa adanya,
            serta dibuat tanpa paksaan dari pihak manapun.
          </span>
          <div className="ttd-row-pdf">
            <span
              classNama="ttd-text-pdf"
              style={{ textTransform: "capitalize" }}
            >
              {getDateFinalisasi(profile?.tanggalFinalisasi)}
            </span>
          </div>
          <div className="line-ttd-blue" />
          <span
            className="ttd-wrapper-pdf-txt"
            style={{
              marginTop: 10,
              textTransform: "uppercase",
              textAlign: "left",
            }}
          >
            {getMyName(profile?.dataPribadi)}
          </span>
        </div>
      </div>
    </div>
  );
}

export default BerkasLengkap;
