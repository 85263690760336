import React, { useState, useEffect } from 'react'
import './styles.scss'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LogoBinaBud from '../../images/homepage/binabud.svg'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getHasilSeleksiChapter } from '../../store/actions/peserta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSearch, faFemale, faMale } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'

const FilterButton = withStyles((theme) => ({
    root: {
        width: '120px',
        height: '44px',
        color: '#999999',
        border: '0.5px solid #D1D1D1',
        backgroundColor: '#FAFAFA',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '21px',
        padding: '0 20px',
        '&:hover': {
            boxShadow: 'none',
        },
    },
}))(Button);

const useStyles = makeStyles(theme => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    table: {
        minWidth: 650,
    },
    tableHeader:{
        background: '#FAFAFA',
        borderRadius: '2px 2px 0px 0px',
    },
    tableHeaderFont : {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#888888',
    },
    tableMain: {
        boxShadow: "none",
        border: '0.5px solid #D1D1D1',
        borderRadius: '2px',
        width: '100%'
    },
    pagination:{
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#999999',
        paddingLeft: 0,
        marginTop: 10
    },
    paginationRight:{
        marginTop: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width : '60%'
    },
    formControl: {
        '& label.Mui-focused': {
            color: '#999999',
        },
        '& label': {
            color: '#999999',
        },
        '& .MuiOutlinedInput-root': {
            width: '200px',
            height: '44px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            transition: '0.8s',
            '&.Mui-focused fieldset': {
                border: '0.5px solid #D1D1D1',
            },
            '&:hover fieldset': {
                border: '0.5px solid #D1D1D1',
            },
        },
    },
    formControl2: {
        '& label.Mui-focused': {
            color: '#999999',
        },
        '& label': {
            color: '#999999',
        },
        '& .MuiSelect-root':{
            paddingTop: 0,
            paddingBottom: 15,
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
            width: 200-10,
            height: '44px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            transition: '0.8s',
            '&.Mui-focused fieldset': {
                border: '0.5px solid #D1D1D1',
                background: 'white',
            },
            '&:hover fieldset': {
                border: '0.5px solid #D1D1D1',
            },
        },
        '& .MuiSelect-iconOutlined':{
            marginRight: 10,
            zIndex: 99
        },
        '&:hover .MuiSelect-iconOutlined':{
            zIndex: 99
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
}));

function StyledCheckbox(props) {
    const classes = useStyles();

    return (
        <Checkbox
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        inputProps={{ 'aria-label': 'decorative checkbox' }}
        {...props}
        />
    );
}

function HasilSeleksi () {
    const hasilSeleksi = useSelector(state=> state.pesertaState.hasilSeleksi )
    const chapterList = useSelector(state=> state.chapterState.chapter )
    const classes = useStyles();
    const Dispatch = useDispatch();
    const History = useHistory();
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [filter, setFilter] = useState({
        urutan: null,
        filterChapter: [],
        program: null,
        gender: null,
        search: ''
    });

    useEffect(()=>{
        Dispatch(getHasilSeleksiChapter(Swal, History))
    },[])

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const ResetAllFilter = () => {
        setFilter({
            urutan: null,
            filterChapter: [''],
            program: null,
            gender: null,
            search: ''
        })
    }

    const getMyName = (dataPribadi) => {
        let namaDepan
        let namaTengah
        let namaBelakang
        if (dataPribadi) {
            namaDepan = dataPribadi.namaDepan
            namaTengah = dataPribadi.namaTengah
            namaBelakang = dataPribadi.namaBelakang
        }
        return `${namaDepan ? namaDepan+' ' : ''}${namaTengah ? namaTengah+' ' : ''}${namaBelakang ? namaBelakang+' ' : ''}`
    }

    const getChapterName = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList?.filter(a=> a._id === chapter )?.[0]
            return temp?.chapter || '-'
        }
    }

    const getValue = (obj, key) => {
        let result
        if (obj) {
            result = obj[key]
        }
        return `${result ? result : '-'}`
    }

    const getNegara = (obj) => {
        let result = []
        if (obj) {
            result = obj
        }
        return result
    }

    const listDrawer = (anchor) => (
        <div
            style={{ width: 305 }}
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 20,
                paddingBottom: 0
            }} >
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ color: '#f44336', cursor: 'pointer'}}
                    onClick={toggleDrawer('right', false)}
                />
            </div>
            <div className="filter-drawer-header" >
                <span className="filter-header-font-drawer-top">
                    Filter
                </span>
                <div style={{ marginTop: 3 }} >
                    <span className="filter-reset-font-drawer" onClick={()=>[ResetAllFilter(), toggleDrawer('right', false)]} >
                        Reset Filter
                    </span>
                </div>
            </div>
            <Divider style={{ height: 1, backgroundColor: '#E3E3E3' }}  />
            <div className="filter-nama-drawer" >
                <span className="filter-title-big-black">
                    Urutkan Nama
                </span>
                <div className="drawer-row">
                    <FormControlLabel
                        onChange={(e)=> handleFilter('urutan', 'asc')}
                        value="asc"
                        control={<StyledCheckbox />}
                        label="A sampai Z"
                        checked={filter.urutan === "asc" ? true : false}
                        labelPlacement="A sampai Z"
                        className={filter.urutan === "asc" ? "label-checkbox-drawer-active" : "label-checkbox-drawer"}
                    />
                    <FormControlLabel
                        onChange={(e)=> handleFilter('urutan', 'desc')}
                        value="desc"
                        checked={filter.urutan === "desc" ? true : false}
                        control={<StyledCheckbox />}
                        label="Z sampai A"
                        labelPlacement="Z sampai A"
                        className={filter.urutan === "desc" ? "label-checkbox-drawer-active" : "label-checkbox-drawer"}
                    />
                </div>
            </div>
            <div className="filter-nama-drawer" >
                <span className="filter-title-big-black">
                    Filter Program
                </span>
                <div className="drawer-row">
                    <FormControlLabel
                        onChange={(e)=> handleFilter('program', 'AFS')}
                        value="AFS"
                        control={<StyledCheckbox />}
                        label="AFS"
                        checked={filter.program === "AFS" ? true : false}
                        labelPlacement="AFS"
                        className={filter.program === "AFS" ? "label-checkbox-drawer-active" : "label-checkbox-drawer"}
                    />
                    <FormControlLabel
                        onChange={(e)=> handleFilter('program', 'YES')}
                        value="YES"
                        checked={filter.program === "YES" ? true : false}
                        control={<StyledCheckbox />}
                        label="YES"
                        labelPlacement="YES"
                        className={filter.program === "YES" ? "label-checkbox-drawer-active" : "label-checkbox-drawer"}
                    />
                    {/* <FormControlLabel
                        onChange={(e)=> handleFilter('program', 'KAKEHASHI')}
                        value="KAKEHASHI"
                        checked={filter.program === "KAKEHASHI" ? true : false}
                        control={<StyledCheckbox />}
                        label="KAKEHASHI"
                        labelPlacement="KAKEHASHI"
                        className={filter.program === "KAKEHASHI" ? "label-checkbox-drawer-active" : "label-checkbox-drawer"}
                    /> */}
                </div>
            </div>
            <div className="filter-nama-drawer" >
                <span className="filter-title-big-black">
                    Filter Kelamin
                </span>
                <div className="drawer-row">
                    <FormControlLabel
                        onChange={(e)=> handleFilter('gender', 'laki-laki')}
                        value="laki-laki"
                        control={<StyledCheckbox />}
                        label="Laki-Laki"
                        checked={filter.gender === "laki-laki" ? true : false}
                        labelPlacement="Laki-Laki"
                        className={filter.gender === "laki-laki" ? "label-checkbox-drawer-active" : "label-checkbox-drawer"}
                    />
                    <FormControlLabel
                        onChange={(e)=> handleFilter('gender', 'perempuan')}
                        value="perempuan"
                        checked={filter.gender === "perempuan" ? true : false}
                        control={<StyledCheckbox />}
                        label="Perempuan"
                        labelPlacement="Perempuan"
                        className={filter.gender === "perempuan" ? "label-checkbox-drawer-active" : "label-checkbox-drawer"}
                    />
                </div>
            </div>
            <div className="filter-chapter-drawer" >
                <span className="filter-title-big-black">
                    Filter Chapter
                </span>
                <div className="drawer-column">
                    {
                        chapterList?.map((chap)=>(
                            <FormControlLabel
                                onChange={e=>handleFilter('filterChapter',e.target.value)}
                                value={chap._id}
                                control={<StyledCheckbox />}
                                label={chap.chapter}
                                checked={(filter.filterChapter).includes(chap._id) ? true : false}
                                labelPlacement={chap.chapter}
                                className={(filter.filterChapter).includes(chap._id) ? "label-checkbox-drawer-active" : "label-checkbox-drawer"}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );

    const getChapterCode = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList?.filter(a=> a._id === chapter )?.[0]
            return temp?.code || '-'
        }
    }

    const handleFilter = (key, value) => {
        switch (key) {
            case 'urutan':
                setFilter({
                    ...filter,
                    urutan: filter.urutan === value ? null : value
                })
                break;
            case 'filterChapter':
                let temp = filter.filterChapter
                if (temp.includes(value)) {
                    temp.splice(temp.indexOf(value), 1);
                } else {
                    temp.push(value)
                }
                setFilter({
                    ...filter,
                    filterChapter: temp
                })
                break;
            case 'program':
                setFilter({
                    ...filter,
                    program: filter.program === value ? null : value
                })
                break;
            case 'gender':
                setFilter({
                    ...filter,
                    gender: filter.gender === value ? null : value
                })
                break;
            case 'search':
                setFilter({
                    ...filter,
                    search: value
                })
                break;
            default:
                break;
        }
    }

    return(
        <div className="hasil-seleksi-wrapper">
            <div className="hasil-seleksi-image-wrapper" >
                <img src={LogoBinaBud} alt="binabudaya_logo" onClick={()=> History.push('/')} style={{cursor: 'pointer'}}/>
            </div>
            <div className="hasil-seleksi-header" >
                Daftar Peserta Lolos Seleksi Bina Antarbudaya 2025-2026
            </div>
            <div className="daftar-table-wrapper" >
                <div className="daftar-filter-table" >
                    <div className="search-filter" style={{ padding: 0, backgroundColor: 'white' }}> 
                        <input type="text" className="filter-table" style={{ width: '100%', borderLeft: 'none', borderRadius: '4px 0px 0px 4px', backgroundColor: 'white', border: '0.5px solid #D1D1D1', borderRight: '0px', color: '#444444' }}  placeholder="Cari Nomor Peserta atau Nama Lengkap"
                            onChange={e=>handleFilter('search',e.target.value)}
                        />
                        <FontAwesomeIcon
                            icon={faSearch}
                            className="search-custom-filter"
                            style={{
                                minWidth: 25,
                                paddingLeft: 15,
                                backgroundColor: '#fafafa',
                                boxSizing: 'content-box'
                            }}
                        />
                    </div>
                    <div>
                        <FilterButton onClick={toggleDrawer('right', true)} > Filter <FilterListIcon/></FilterButton>
                        <SwipeableDrawer
                            anchor={'right'}
                            open={state['right']}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                        {listDrawer('right')}
                        </SwipeableDrawer>
                    </div>
                </div>
                <div className="daftar-table">
                    <TableContainer className={classes.tableMain} component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow className={classes.tableHeader} >
                                    <TableCell className={classes.tableHeaderFont} align="left" style={{ minWidth: 50 }} >NO</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left" style={{ minWidth: 200 }}>NOMOR PESERTA</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left" style={{ minWidth: 250 }}>NAMA LENGKAP</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left" style={{ minWidth: 100 }}>CHAPTER</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left" style={{ minWidth: 80 }}>PROGRAM</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left" style={{ minWidth: 200 }}>ASAL SEKOLAH</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left" style={{ minWidth: 200 }}>NEGARA TUJUAN</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                hasilSeleksi &&
                                hasilSeleksi
                                .filter(a=> filter.filterChapter[0] ? filter.filterChapter.includes(a.chapterId) : a )
                                .filter(a=> filter.program ? filter.program === a.program : a )
                                .filter(a=> filter.gender ? filter.gender === getValue(a && a.dataPribadi, 'kelamin') : a )
                                .filter(a=> filter.search ? `${getMyName(a.dataPribadi)}YBA/YP24-25/${getChapterCode(chapterList, a && a.chapterId)}/${a && a.program === 'KAKEHASHI' ? 'MXT': a && a.program}/${ a && (a.nomorUrut !== '0000') ?  a.nomorUrut : '-'}${getValue(a && a.riwayatPendidikan, 'namaSMA')}`.includes(filter.search) : a )
                                .sort((a,b)=>{
                                    if (filter.urutan) {
                                        if (filter.urutan === 'asc') {
                                            return (getMyName(a.dataPribadi) < getMyName(b.dataPribadi)) ? -1 : (getMyName(b.dataPribadi) > getMyName(a.dataPribadi)) ? 1 : 0
                                        } else if (filter.urutan === 'desc') {
                                            return (getMyName(a.dataPribadi) > getMyName(b.dataPribadi)) ? -1 : (getMyName(b.dataPribadi) < getMyName(a.dataPribadi)) ? 1 : 0
                                        }
                                    } else {
                                        return (a.nomorUrut < b.nomorUrut) ? -1 : (b.nomorUrut > a.nomorUrut) ? 1 : 0
                                    }
                                })
                                .map((peserta,i) => (
                                    <TableRow key={i}  className="table-hover-style-custom">
                                        <TableCell component="th" scope="row">
                                            { i + 1 }
                                            <FontAwesomeIcon
                                                icon={getValue(peserta && peserta.dataPribadi, 'kelamin') === 'perempuan' ? faFemale : faMale}
                                                style={{ marginLeft: 8, color: getValue(peserta && peserta.dataPribadi, 'kelamin') === 'perempuan' ? '#e91e63' : '#2196f3', fontSize: 20,  }}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                        YBA/YP25-26/
                                        {
                                            getChapterCode(chapterList, peserta && peserta.chapterId)
                                        }
                                        /
                                        {
                                            peserta &&
                                            peserta.program === 'KAKEHASHI' ?
                                            'MXT'
                                            :
                                            peserta &&
                                            peserta.program
                                        }
                                        /
                                        {
                                            peserta &&
                                            (peserta.nomorUrut !== '0000') ? 
                                            peserta.nomorUrut
                                            :
                                            '-'
                                        }
                                        </TableCell>
                                        <TableCell align="left">{getMyName(peserta.dataPribadi)}</TableCell>
                                        <TableCell align="left"
                                            style={{
                                                textTransform: 'capitalize'
                                            }}
                                        >{
                                            getChapterName(chapterList, peserta && peserta.chapterId)
                                        }</TableCell>
                                        <TableCell align="left">{
                                            peserta.program
                                        }</TableCell>
                                        <TableCell align="left">{getValue(peserta && peserta.riwayatPendidikan, 'namaSMA')}</TableCell>
                                        <TableCell align="left">
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }} >
                                                {
                                                    getNegara(peserta && peserta.pilihanNegara)
                                                    .map((data,i)=>(
                                                        data &&
                                                        <span className="page2-row-right" style={{ display: 'flex', flexDirection: 'row', width: '100%', textTransform: 'capitalize', padding: '1px 0px' }} >
                                                            { (peserta && peserta.program === 'AFS') && `${i+1}.`} { data ? data : '-' }
                                                        </span>
                                                    ))
                                                }
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    )
}

export default HasilSeleksi