import React,{ Fragment } from 'react';
import Backdrop from './backdrop';
import './styles.scss'
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const ButtonBlue = withStyles((theme) => ({
    root: {
        color: '#FFFFFF',
        backgroundColor: '#3598DC',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#3598DC',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
    },
}))(Button);

const ButtonGrey = withStyles((theme) => ({
    root: {
        color: '#3598DC',
        border: '1px solid #3598DC',
        backgroundColor: ' rgba(53, 152, 220, 0.15)',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        marginTop: '10px',
        '&:hover': {
            backgroundColor: ' rgba(53, 152, 220, 0.15)',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(53, 152, 220, 0.12), 0px 10px 20px rgba(53, 152, 220, 0.18)',
        },
    },
}))(Button);

const ButtonWhite = withStyles((theme) => ({
    root: {
        marginTop: '10px',
        color: '#c9c9c9',
        backgroundColor: '#FFFFFF',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        border: '1px solid #c9c9c9',
        '&:hover': {
            backgroundColor: '#FFFFFF'
        },
    },
}))(Button);

const ButtonWhiteOn = withStyles((theme) => ({
    root: {
        marginTop: '10px',
        color: '#444444',
        backgroundColor: '#FFFFFF',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        border: '1px solid #8e8e8e',
        '&:hover': {
            backgroundColor: '#FFFFFF'
        },
    },
}))(Button);

function Modal(props) {
    let {
        program
    } = props
    const History = useHistory()
    const options = useSelector(state=> state.pesertaState.options )

    const HandleLogin = () => {
        document.getElementById('html-overflow').style.overflow="overlay"
        History.push('/masuk?program='+(program).toLowerCase())
        goTop()
    }

    const HandleRegister = () => {
        document.getElementById('html-overflow').style.overflow="overlay"
        History.push('/daftar?program='+(program).toLowerCase())
        goTop()
    }

    const goTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const goToHasilSeleksi = () => {
        History.push('/hasil-seleksi')
    }

    return (
        <Fragment>
            <Backdrop show={props.show} clicked={e=>props.modalClosed(e, 'noToken', null, false)} />
            <div
                id="modal-id-notoken"
                className="ModalChapter"
                style={{
                    display : props.show ? 'block' : 'none',
                    opacity : props.show ? 1 : 0,
                }}
            >
                <div className="modal-noToken-wrapper" >
                    <div className="modal-header-1235" >
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="fa-times-closed2414"
                            onClick={e=>props.modalClosed(e, 'noToken', null, false)}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0px 50px',
                        marginBottom: 15
                    }}>
                        <span className="modal-notoken-text1" >
                            Masa Pendaftaran:
                        </span>
                        <span className="modal-notoken-dateblue" >
                            05 - 23 Agustus 2024
                        </span>
                        <span className="modal-notoken-dateblue-on" >
                            05 - 23 Agustus 2024
                        </span>
                        <span className="modal-notoken-info" >
                            Silahkan masuk bagi adik yang sudah aktivasi akun
                        </span>
                        <ButtonBlue className="modal-notoken-btn1" onClick={HandleLogin} >
                            Masuk
                        </ButtonBlue>
                        <ButtonGrey className="modal-notoken-btn1" onClick={HandleRegister} >
                            Buat Akun
                        </ButtonGrey>
                        {
                            options?.[program]?.hasilSeleksi ?
                            <ButtonWhiteOn
                                onClick={goToHasilSeleksi}
                                className="modal-notoken-btn2"
                            >
                                Lihat Hasil Seleksi
                            </ButtonWhiteOn>
                            :
                            <ButtonWhite
                                className="modal-notoken-btn2"
                            >
                                Lihat Hasil Seleksi
                            </ButtonWhite>
                        }
                    </div>
                    <div
                        style={{
                            marginTop: 'auto',
                            padding: '0px 30px',
                            paddingBottom: 20,
                        }}
                    >
                        <span className="modal-notoken-textinfosmall">
                            {
                                program == 'YES' ?
                                    "Apabila adik memiliki pertanyaan atau membutuhkan informasi tentang program YES. Silahkan kirimkan email ke ina.support-yes@afs.org"
                                :
                                program == 'KAKEHASHI' ?
                                    "Apabila adik memiliki pertanyaan atau membutuhkan informasi tentang program ASIA KAKEHASHI PROJECT. Silahkan kirimkan email ke ina.support-kks@afs.org"
                                :
                                ''
                            }
                        </span>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Modal;